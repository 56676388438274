import React, {useCallback, useRef, useState} from "react";
import styles from "./print.module.css";
import {useTranslation} from "react-i18next";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import TableView from "../../molecules/table/TableView";
import PrintView from "../../molecules/print/PrintView";
import {showMessageWithTitle} from "../../../fns/message";
import {getInvoiceCntSts, getPrintList, updateInvoicePrinted,} from "../../../api/shippingApi";
import {VerticalInvoiceFormat} from "@delivus/daas-print-lib";
import {AxiosError} from "axios";
import {updateInvoicePendingCnt} from "../../../reducers/profileReducer";
import {useDispatch} from "react-redux";
import {ContainerApiResponse, DamagedInvoiceType,} from "../../../api/apiTypes";
import {format} from "date-fns";
import SearchView, {SearchResultType,} from "../../organisms/searchView/SearchView";
import TableCell from "../../molecules/table/TableCell";

const PrintInvoiceScreen = () => {
    const {t} = useTranslation(["print"]);
    const dispatch = useDispatch();
    const selectedRowsRef = useRef<any[]>();
    const dataRef = useRef<any[]>();
    const printRef = useRef<any>();
    const [params, setParams] = useState<any>();
    const [refresh, setRefresh] = useState(true);

    const handleSearch = useCallback(
        ({selectedOption, inputValue}: SearchResultType) => {
            let params: any = {};
            if (selectedOption) {
                params[selectedOption] = inputValue;
                setParams(params);
            }
        },
        []
    );

    const handleSelect = useCallback((data: any[]) => {
        selectedRowsRef.current = data;
    }, []);

    const handleGetCount = useCallback((count: number, data: any[]) => {
        dataRef.current = data;
        setRefresh(false);
    }, []);

    const handlePrint = (data?: any) => {
        const array = !!data && data.length > 0 ? data : selectedRowsRef.current;
        const trackingList: string[] = [];
        if (!!array && array.length > 0) {
            let pendingCnt = 0;
            array.forEach((s: any) => {
                if (!s.shipping_status || s.shipping_status === "UPLOADED") {
                    // 이미 발송 진행한 주문
                    pendingCnt++;
                    !!s.tracking_number && trackingList.push(s.tracking_number);
                }
            });
            if (pendingCnt === 0) {
                showMessageWithTitle(
                    t("popup.print.title"),
                    t("error.popup.print.nopending"),
                    "warning"
                );
            } else {
                if (!!printRef.current) {
                    printRef.current.show();
                }
                getPrintList(
                    trackingList,
                    (data) => {
                        const invoivesNode = data.map(({product_info, ...order}, index) => (
                            <VerticalInvoiceFormat key={"print" + index} product_info={product_info} {...order} type={"A"}/>
                        ));
                        if (!!printRef.current) {
                            printRef.current.setContent(invoivesNode);
                        }
                    },
                    trackingList?.length
                );
            }
        } else {
            showMessageWithTitle(
                t("popup.print.title"),
                t("error.popup.print.notselected"),
                "warning"
            );
        }
    };

    const handlePrinted = () => {
        console.log("handleAfterPrint");
        window.onfocus = null;
        let successOrderCnt = 0;
        if (dataRef.current) {
            const req = dataRef.current.map(
                (o: any): Promise<number> => {
                    return new Promise<number>((resolve) => {
                        if (o) {
                            updateInvoicePrinted(
                                o.pk,
                                () => {
                                    resolve(++successOrderCnt);
                                },
                                (err: AxiosError) => {
                                    resolve(successOrderCnt);
                                }
                            );
                        } else {
                            resolve(successOrderCnt);
                        }
                    });
                }
            );
            Promise.all<number>(req).then((cnt) => {
                console.log("handleAfterPrint", cnt, successOrderCnt);
                setRefresh((prev) => !prev);
                getInvoiceCntSts((res) => {
                    dispatch(updateInvoicePendingCnt(res.num_collect_invoice_pending));
                });
                showMessageWithTitle(
                    t("popup.print.title"),
                    t("success.popup.print"),
                    "warning"
                );
            });
        }
    };

    const handlePrintAll = () => {
        handlePrint(dataRef.current);
    };

    const columns = [
        {
            title: (
                <div key={"box_alias"} className={"bold body"}>
                    {t("box_alias")}
                </div>
            ),
            width: 60,
            dataIndex: "box_alias",
            render: (text: string, p: DamagedInvoiceType) => (
                <TableCell className={"body link table-column"} refreshing={refresh}>
                    {p.box_alias || "-"}
                </TableCell>
            ),
        },
        {
            title: (
                <div key={"identifier"} className={"bold body"}>
                    {t("identifier")}
                </div>
            ),
            width: 80,
            dataIndex: "indetifier",
            render: (text: string, p: DamagedInvoiceType) => (
                <TableCell className={"body underline"} refreshing={refresh}>
                    {p.identifier || "-"}
                </TableCell>
            ),
        },
        {
            title: <div className={"bold body"}>{t("item_alias")}</div>,
            width: 130,
            dataIndex: "item_alias",
            render: (text: string, p: DamagedInvoiceType) => (
                <TableCell className={"body"} refreshing={refresh}>
                    {p.item_alias || "-"}
                </TableCell>
            ),
        },
        {
            title: <div className={"bold body"}>{t("tracking_number")}</div>,
            width: 100,
            dataIndex: "tracking_number",
            render: (text: string, p: DamagedInvoiceType) => (
                <TableCell className={"body"} refreshing={refresh}>
                    {p.tracking_number || "-"}
                </TableCell>
            ),
        },
        {
            title: <div className={"bold body"}>{t("receiver_name")}</div>,
            dataIndex: "receiver_name",
            width: 100,
            render: (text: string, p: DamagedInvoiceType) => (
                <TableCell className={"body"} refreshing={refresh}>
                    {p.receiver_name}
                </TableCell>
            ),
        },
        {
            title: <div className={"bold body"}>{t("status")}</div>,
            width: 90,
            dataIndex: "status",
            render: (text: string, p: ContainerApiResponse) => (
                <TableCell className={"row align-center body"} refreshing={refresh}>
                    <div
                        className={
                            styles.dot +
                            " " +
                            (p.status === "FINISHED" ? styles.inactive : "")
                        }
                    />
                    {t(p.status)}
                </TableCell>
            ),
        },
        {
            title: <div className={"bold body"}>{t("rider_phonenumber")}</div>,
            width: 90,
            dataIndex: "rider",
            key: "rider",
            render: (text: string, p: DamagedInvoiceType) => (
                <TableCell className={"body table-column"} refreshing={refresh}>
                    {p?.rider_phonenumber || "-"}
                </TableCell>
            ),
        },
        {
            title: <div className={"bold body"}>{t("timestamp_created")}</div>,
            width: 160,
            dataIndex: "phone",
            key: "phone",
            render: (text: string, p: DamagedInvoiceType) => (
                <TableCell className={"body row align-center"} refreshing={refresh}>
                    {!!p.timestamp_created
                        ? format(new Date(p.timestamp_created), "yyyy.MM.dd HH:mm:ss")
                        : "-"}
                </TableCell>
            ),
        },
    ];

    const searchOptions = [
        {title: t("search.select.search_param"), value: "search_param"},
        {title: t("search.select.box_alias"), value: "box_alias"},
        {title: t("search.select.phonenumber"), value: "phonenumber"},
    ];
    const searchBtns = [
        {title: t("print.btn.selected"), onClick: handlePrint},
        {title: t("print.btn.all"), onClick: handlePrintAll},
    ];

    return (
        <LeftTitledTemplate classname={styles.cntr}>
            <SearchView
                options={searchOptions}
                buttons={searchBtns}
                onSearch={handleSearch}
            />
            <TableView
                scrollX={1250}
                scrollY={"calc(var(--app-height) - var(--navbar-height) - 220px)"}
                onChange={handleSelect}
                params={params}
                refresh={refresh}
                columns={columns}
                sortColumn={"timestamp"}
                onGetData={handleGetCount}
                url={"/v1/unit/shippingitems/collect_invoices/"}
                t={t}
            />
            <PrintView ref={printRef} onPrinted={handlePrinted}/>
        </LeftTitledTemplate>
    );
};

export default PrintInvoiceScreen;
