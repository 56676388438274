import React, {useEffect} from "react";
import "./App.css";
import "@delivus/react-open-layers/dist/esm/index.css";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "./reducers/reducer";
import {clearAuthState, updateAuthenticated} from "./reducers/authReducer";
import ProtectedRouteComponent from "./router/ProtectedRouteComponent";
import ProfileExpired from "./components/screens/launch/ProfileExpired";
import MainScreen from "./components/screens/main/MainScreen";
import {clearProfile, updateBoxSts, updateLocationSts, updateProfile,} from "./reducers/profileReducer";
import {getUser} from "./api/userApi";
import {TokenStorage} from "./services/token.service";
import {getContainerSts} from "./api/shippingApi";
import {getLocationSts} from "./api/locationApi";
import DispatchListScreen from "./components/screens/dispatchList/DispatchListScreen";
import {setResponse} from "./services/http.service";
import {signout} from "./api/authApi";
import {showApiError} from "./fns/message";
import LoginPhoneContainer from "./components/screens/login/LoginPhoneContainer";
import ScanShippingItemScreen from "./components/screens/ScanShippingItemScreen";
import BoxListScreen from "./components/screens/boxList/BoxListScreen";
import ScanReturnItemScreen from "./components/screens/ScanReturnItemScreen";
import BoxRegister from "./components/screens/box/BoxRegister";
import PlanMapScreen from "./components/screens/plan/PlanMapScreen";
import BoxDetailScreen from "./components/screens/boxDetail/BoxDetailScreen";
import ContainerManageScreen from "./components/screens/containerManage/ContainerManageScreen";
import ScanBoxScreen from "./components/screens/ScanBoxScreen";
import ShippingMapScreen from "./components/screens/shippingMap/ShippingMapScreen";
import PrintInvoiceScreen from "./components/screens/print/PrintInvoiceScreen";
import PickupMapScreen from "./components/screens/routes/PickupMapScreen";
import DropoffMapScreen from "./components/screens/routes/DropoffMapScreen";
import AuthRouteComponent from "./router/AuthRouteComponent";

declare global {
    interface Window {
        $: any;
        Cypress: any;
        initialState: any;
        install: any;
        installed: boolean;
    }
}

if (process.env.REACT_APP_STAGE === "PROD") {
    console.log = function no_console() {
    };
}

function App() {
    const dispatch = useDispatch();
    let verifyCalled = false;
    const {authenticated} = useSelector((state: RootState) => state.auth);

    const appHeight = () => {
        const doc = document.documentElement;
        setTimeout(() => {
            if (doc) {
                let vh = window.innerHeight * 0.01;
                doc.style.setProperty("--vh", `${vh}px`);
            }
        }, 100);
    };

    const onContext = (e: any) => {
        // e.preventDefault();
    };

    const onBeforeAppInstalled = (e: any) => {
        e.preventDefault();
        window.install = e;
    };

    const onAppInstalled = () => {
        window.installed = true;
    };

    const onContentLoaded = () => {
        if (
            // @ts-ignore
            navigator?.standalone ||
            window.matchMedia("(display-mode: standalone)").matches ||
            window.matchMedia("(display-mode: fullscreen)").matches ||
            window.matchMedia("(display-mode: minimal-ui)").matches
        ) {
            window.installed = true;
        }
    };

    useEffect(() => {
        setResponse(dispatch);
        document.addEventListener("contextmenu", onContext);
        window.addEventListener("resize", appHeight);
        appHeight();
        window.addEventListener("beforeinstallprompt", onBeforeAppInstalled);

        if (!navigator.serviceWorker) {
            window.installed = true;
        }

        window.addEventListener("appinstalled", onAppInstalled);

        //but also add a listener. After app installation on desktop, the app will open in their own window right away.
        window.addEventListener("DOMContentLoaded", onContentLoaded);

        return () => {
            window.removeEventListener("resize", appHeight);
            window.removeEventListener("contextmenu", onContext);
            window.removeEventListener("beforeinstallprompt", onBeforeAppInstalled);
            window.removeEventListener("DOMContentLoaded", onContentLoaded);
            window.removeEventListener("appinstalled", onAppInstalled);
        };
    }, []);

    useEffect(() => {
        if (TokenStorage.isAuthenticated() && !verifyCalled) {
            verifyCalled = true;
            TokenStorage.verifyToken(onTokenValid, onRefreshError);
        }
    }, []);

    useEffect(() => {
        console.log("clearProfile", authenticated);
        if (authenticated === false) {
            const refresh = TokenStorage.getRefreshToken();
            if (refresh) {
                signout(
                    refresh,
                    () => {
                        TokenStorage.clear();
                        dispatch(clearProfile());
                    },
                    (e) => {
                        showApiError(e);
                        TokenStorage.clear();
                        dispatch(clearProfile());
                    }
                );
            }
        }
    }, [authenticated, dispatch]);

    const onTokenValid = () => {
        dispatch(updateAuthenticated(true));
        getUser((pro) => {
            dispatch(updateProfile(pro));
        });
        getLocationSts((res) => {
            dispatch(updateLocationSts(res));
        });
        getContainerSts((cnt) => {
            dispatch(updateBoxSts(cnt));
        });
    };

    const onRefreshError = (e: any) => {
        dispatch(clearProfile());
        dispatch(clearAuthState());
    };

    return (
        <Router>
            <Routes>
                <Route path="/logout" element={<ProfileExpired/>}/>
                <Route path={"/home"}>
                    <Route path="/home/scan" element={<ProtectedRouteComponent
                        isLoggedIn={authenticated} showBreadTitle={false}><ScanShippingItemScreen/></ProtectedRouteComponent>}/>
                    <Route path="/home/list" element={<ProtectedRouteComponent
                        isLoggedIn={authenticated} showBreadTitle={false}><BoxListScreen/></ProtectedRouteComponent>}/>
                    <Route path="/home/scan/return" element={<ProtectedRouteComponent
                        isLoggedIn={authenticated} showBreadTitle={false}><ScanReturnItemScreen/></ProtectedRouteComponent>}/>
                    <Route path="/home/box" element={<ProtectedRouteComponent isLoggedIn={authenticated} showBreadTitle={false}><BoxRegister/></ProtectedRouteComponent>}/>
                    <Route path="/home/plan" element={<ProtectedRouteComponent
                        isLoggedIn={authenticated} showBreadTitle={false}><PlanMapScreen/></ProtectedRouteComponent>}/>
                    <Route
                        path="/home/list/detail/:uuid/:cntrUuid/"
                        element={<ProtectedRouteComponent
                            isLoggedIn={authenticated} showBreadTitle={false}><BoxDetailScreen/></ProtectedRouteComponent>}
                    />
                    <Route path="/home/return" element={<ProtectedRouteComponent
                        isLoggedIn={authenticated} showBreadTitle={false}><ContainerManageScreen/></ProtectedRouteComponent>}/>
                    <Route
                        path="/home/box/detail/:uuid/:cntrUuid/"
                        element={<ProtectedRouteComponent
                            isLoggedIn={authenticated} showBreadTitle={false}><BoxDetailScreen/></ProtectedRouteComponent>}
                    />
                    <Route
                        path="/home/box/scan/:sector/:name/"
                        element={<ProtectedRouteComponent
                            isLoggedIn={authenticated} showBreadTitle={false}><ScanBoxScreen/></ProtectedRouteComponent>}
                    />
                </Route>
                <Route path={"/status"}>
                    <Route path="/status/shipping/" element={<ProtectedRouteComponent
                        isLoggedIn={authenticated} showBreadTitle={false}><ShippingMapScreen/></ProtectedRouteComponent>}/>
                    <Route path="/status/rider/" element={<ProtectedRouteComponent
                        isLoggedIn={authenticated}><PrintInvoiceScreen/></ProtectedRouteComponent>}/>
                </Route>
                <Route path={"/route"}>
                    <Route path="/route/pickup/" element={<ProtectedRouteComponent
                        isLoggedIn={authenticated} showBreadTitle={false}><PickupMapScreen/></ProtectedRouteComponent>}/>
                    <Route path="/route/dropoff/" element={<ProtectedRouteComponent
                        isLoggedIn={authenticated} showBreadTitle={false}><DropoffMapScreen/></ProtectedRouteComponent>}/>
                </Route>
                <Route path={"/dispatch/list"} element={<ProtectedRouteComponent isLoggedIn={authenticated}>
                    <DispatchListScreen/>
                </ProtectedRouteComponent>}/>
                <Route path={"/main/guide"} element={<ProtectedRouteComponent showBreadTitle={false} isLoggedIn={authenticated}>
                    <MainScreen/>
                </ProtectedRouteComponent>}/>
                <Route path="/auth" element={<LoginPhoneContainer/>}>
                    <Route index path={"/auth/login"} element={<AuthRouteComponent><LoginPhoneContainer/></AuthRouteComponent>}/>
                </Route>
                <Route
                    path="*"
                    element={<Navigate to="/main/guide" replace />}
                />
            </Routes>
        </Router>
    );
}

export default App;
