import React, { useMemo } from "react";
import { BunnyType, ContainerType } from "../../../api/apiTypes";
import "./typeSelector.css";

const TypeItem = ({
  type,
  title,
  className,
  is_plan,
}: {
  type?: BunnyType | ContainerType | "PLAN";
  title?: string;
  className?: string;
  is_plan?: boolean;
}) => {
  const typeClass = useMemo(() => {
    switch (type) {
      case ContainerType.BLACK:
        return "bunny-black";
      case ContainerType.BLUE:
        return "bunny-blue";
      case ContainerType.WHITE:
        return "bunny-white";
      case ContainerType.OFFROAD:
        return "bunny-offroad";
      case ContainerType.RAINBOW:
        return "bunny-rainbow";
      case ContainerType.YELLOW:
        return "bunny-yellow";
      case ContainerType.ORANGE:
        return "bunny-orange";
      case "PLAN":
        return is_plan ? "plan primary" : "plan black";
      case BunnyType.STAFF:
      case BunnyType.FIXED:
      case BunnyType.FLEX:
        return "staff";
    }
  }, [type, is_plan]);

  if (!type) {
    return <span>-</span>;
  }
  return (
    <span
      className={
        typeClass + " bunny-type small medium text-center " + className
      }
    >
      {title || type}
    </span>
  );
};

export default TypeItem;
