import React, {useCallback, useRef, useState} from "react";
import "./planMap.css";
import {useTranslation} from "react-i18next";
import {
    IClusterPlanLongItem,
    IGetPlanLongAPIParam,
} from "../../../api/apiTypes";
import {CustomBreadcrumb} from "../../organisms/breadCrumb/CustomBreadcrumb";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import {format} from "date-fns";
import PlanMap, {IShippingPlanMarker} from "../../organisms/plan/PlanMap";
import PlanTable from "../../organisms/plan/PlanTable";
import {PlanControlButtons} from "../../organisms/plan/PlanControlButtons";
import {RoundSelector} from "../../organisms/plan/RoundSelector";
import DatePicker from "../../molecules/datepicker/DatePicker";
import {Row, Space} from "antd";
import SelectedShippingPlans from "../../organisms/plan/SelectedShippingPlans";
import {ClusterIDSearch} from "../../organisms/plan/ClusterIDSearch";
import {SectorTable} from "../../organisms/plan/SectorTable";

export type ObjectWith = {
    [x: string]: any;
};

const now = new Date();
const PlanMapScreen = () => {
    const {t} = useTranslation(["shipping"]);
    const {t: tCommon} = useTranslation(["common"]);
    const mapRef = useRef<any>();
    const controlRef = useRef<any>();
    const shippingControlRef = useRef<any>();
    const tableRef = useRef<any>();
    const [planParams, setPlanParams] = useState<IGetPlanLongAPIParam>({
        plan_date: format(now, "yyyy-MM-dd"),
    });

    const handleFilterBySector = (optionValues: string[]) => {
        setPlanParams((prevState) => {
            console.log("handleFilterBySector", optionValues, planParams);
            const {cluster_id_startswith_in_csv, ...rest} = prevState;
            if (!optionValues || optionValues.length <= 0) {
                return rest;
            }
            return {...rest, cluster_id_startswith_in_csv: optionValues.toString()};
        });
    };

    const handleFilterDate = (date: any) => {
        console.log("handleFilterDate", format(new Date(date), "yyyy-MM-dd"));

        setPlanParams((prevState) => ({
            ...prevState,
            plan_date: format(new Date(date), "yyyy-MM-dd"),
        }));
    };

    const handleFilterByRound = (selectedOption: string | number) => {
        setPlanParams((prevState) => {
            const {plan_no, ...rest} = prevState;
            if (selectedOption === "ALL") return rest;
            return {...rest, plan_no: selectedOption};
        });
    };

    const handleFilterByCluster = (selectedOption: string) => {
        setPlanParams((prevState) => {
            const {cluster_id__startswith, ...rest} = prevState;
            if (selectedOption === "ALL") return rest;
            return {...rest, cluster_id__startswith: selectedOption};
        });
    };

    const handleResetSelectedShippings = useCallback(() => {
        if (mapRef.current) {
            mapRef.current.reset();
        }
    }, []);

    const handleShippingShifted = useCallback(() => {
        console.log("handleUpdateShipping");
        if (tableRef.current) {
            tableRef.current.requestRefresh();
        }
    }, []);

    const handleMergePlans = useCallback(() => {
        console.log("handleMergePlans");
        if (tableRef.current) {
            tableRef.current.requestRefresh(false, true);
        }
    }, []);

    const handleSelectShippings = useCallback(
        (shipping: IShippingPlanMarker[]) => {
            console.log("handleSelectShippings", shipping);
            if (shippingControlRef.current) {
                shippingControlRef.current.setShippings(shipping);
            }
            if (controlRef.current) {
                controlRef.current.setShippings(shipping);
            }
        },
        []
    );

    const handleReset = useCallback(() => {
        if (controlRef.current) controlRef.current.setShippings([]);
        if (shippingControlRef.current) shippingControlRef.current.setShippings([]);
    }, []);

    const handleSelectPlans = useCallback((plans: IClusterPlanLongItem[]) => {
        console.log("handleSelectPlans", plans);
        if (controlRef.current) {
            controlRef.current.setPlans(plans);
        }
        if (mapRef.current) {
            mapRef.current.setPlans(plans);
        }
        handleReset();
        handleResetSelectedShippings();
    }, [handleReset, handleResetSelectedShippings]);

    const handleRefresh = useCallback(() => {
        setPlanParams({plan_date: format(now, "yyyy-MM-dd")});
        handleResetSelectedShippings();
        handleSelectPlans([]);
    }, [handleResetSelectedShippings, handleSelectPlans]);

    const headerNode = (
        <div className={"row align-center plan-header"}>
            <CustomBreadcrumb t={tCommon}/>
            <Space className={"row control align-center"} size={8}>
                <div style={{marginRight: 8}} className={"body1"}>검색 입력</div>
                <ClusterIDSearch
                    onChange={handleFilterByCluster}
                />
                <DatePicker
                    defaultValue={now}
                    value={planParams.plan_date ? new Date(planParams.plan_date) : now}
                    placeholder={t("plan date")}
                    onChange={handleFilterDate}
                />
                <RoundSelector
                    value={planParams.plan_no}
                    onChange={handleFilterByRound}
                />
                <PlanControlButtons
                    ref={controlRef}
                    params={planParams}
                    onShifted={handleShippingShifted}
                    onMerged={handleMergePlans}
                    planNo={planParams.plan_no}
                    planDate={planParams.plan_date}
                />
            </Space>
        </div>
    );

    const filterNode = (
        <div className={"column plan-filter-cntr"}>
            <Row className={"plan-filter-upper"}>
                <div className={"round-border-cntr sector-table"}>
                    <SectorTable onSearch={handleFilterBySector}/>
                </div>
                <div className={"round-border-cntr flex"}>
                    <PlanTable
                        ref={tableRef}
                        params={planParams}
                        onSelectData={handleSelectPlans}
                    />
                </div>
            </Row>
            <div className={"plan-filter-lower"}>
                <SelectedShippingPlans
                    ref={shippingControlRef}
                    onReset={handleResetSelectedShippings}
                />
            </div>
        </div>
    );

    console.log("handleFilter render", planParams);
    return (
        <LeftTitledTemplate classname={"shipping-map-container"}>
            <div className={"shipping-map column"}>
                {headerNode}
                <div className={"row"}>
                    {filterNode}
                    <PlanMap
                        ref={mapRef}
                        onSelectShippings={handleSelectShippings}
                        onRefresh={handleRefresh}
                        onReset={handleReset}
                    />
                </div>
            </div>
        </LeftTitledTemplate>
    );
};

export default PlanMapScreen;
