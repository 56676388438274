import React from "react";
import { Button, Col } from "antd";
import styles from "./mainCount.module.css";
import { useNavigate } from "react-router-dom";

type CountType = { count?: number | string; label?: string };
export type MainCountProps = {
  counts?: CountType[];
  label: string;
  link?: string;
  dataCy?: string;
  onClick?: () => void;
};
const MainCountView = ({
  counts,
  link,
  label,
  onClick,
  dataCy,
}: MainCountProps) => {
  const navigate = useNavigate();
  function handleClick() {
    !!link && navigate(link);
    onClick && onClick();
  }

  return (
    <Col xs={12} sm={12} md={6}>
      <Button
        className={styles.whiteCntr + " column align-center"}
        data-cy={dataCy}
        onClick={handleClick}
      >
        <span className={"body1 bold black85 " + styles.label}>{label}</span>
        <div className={"row space-between " + styles.countsContainer}>
          {counts &&
            counts.map((c) => (
              <div className={"flex column align-center"}>
                <div className={"small bold black45 "}>{c.label}</div>
                <div className={"title bold black85 " + styles.count}>
                  {c.count}
                </div>
              </div>
            ))}
        </div>
      </Button>
    </Col>
  );
};

export default MainCountView;
