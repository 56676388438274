import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState,} from "react";
import {Pagination, Table} from "antd";
import "./table.style.css";
import TableCell from "./TableCell";
import {format} from "date-fns";
import {useTableData} from "../../../api/useTableData";

export type CustomTableRef = {
  setSelectedRowKeys: () => void;
  setData: () => void;
  requestRefresh: () => void;
}

export interface CustomTableProps {
  data?: any[];
  header?: string[];
  columns?: any[];
  rowSelection?: any;
  pagination?: boolean;
  scrollY?: string | number;
  scrollX?: string | number | false;
  url?: string | null;
  style?: any;
  sortColumn?: string;
  className?: string;
  tableStyle?: React.CSSProperties;
  rowKey?: string;
  locale?: any;
  paginationClass?: any;
  refresh?: boolean;
  defaultSelected?: boolean;
  shouldLoadData?: boolean;
  params?: any;
  reset?: boolean;
  t: any;
  onChange?: (rows: any[], keys: any[], page?: number) => void;
  onSelect?: (selectedRow: any) => void;
  onLink?: (index: any) => void;
  defaultPageSize?: number;
  onGetData?: (cnt: number, data: any[], page?: number, pageSize?: number) => void;
  onGetCount?: (cnt: number, page?: number, pageSize?: number) => void;
}

const emptyData = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
const TableView = forwardRef(({
                                scrollX = 3500,
                                scrollY = 400,
                                defaultPageSize = 50,
                                sortColumn,
                                paginationClass,
                                rowSelection = {},
                                pagination = true,
                                className,
                                rowKey,
                                reset,
                                url,
                                style,
                                tableStyle,
                                locale,
                                header,
                                onChange,
                                onSelect,
                                refresh,
                                defaultSelected,
                                shouldLoadData = true,
                                columns: columnsProp,
                                params: paramsProp,
                                onLink,
                                data: dataProp,
                                t,
                                onGetData,
                                onGetCount,
                              }: CustomTableProps, ref) => {
  const [columns, setColumns] = useState<any[]>();
  const [params, setParams] = useState<any>(paramsProp);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [data, setData] = useState<any[] | undefined>(dataProp);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  
  const {urlData, refreshing, mutate} = useTableData(shouldLoadData && !(dataProp && dataProp.length > 0)
    ? url
    : null, params, page, pageSize);
  
  const addKeyToData = (data?: any[]) => {
    const keys: any = [];
    if (data) {
      data.slice()
          .forEach((d: any, index: number) => {
            if (rowKey) {
              //field name used as key when select row
              d.key = d[rowKey];
              keys.push(d[rowKey]);
            }
            else {
              d.key = index;
              keys.push(index);
            }
          });
    }
    setData(data);
    if (defaultSelected) {
      setSelectedRowKeys(keys);
      handleChangeRow(keys, data);
    }
  };
  
  useEffect(() => {
    if (!dataProp || dataProp.length === 0) {
      addKeyToData(urlData?.results);
      if (urlData && onGetData) onGetData(urlData.count, urlData.results, page, pageSize);
      if (urlData && onGetCount) onGetCount(urlData.count, page, pageSize);
    }
    else {
      addKeyToData(dataProp);
    }
  }, [dataProp, urlData]);
  
  useImperativeHandle(ref, () => ({
    setSelectedRowKeys, setData, requestRefresh: mutate,
  }), []);
  
  useEffect(() => {
    if (reset) {
      setSelectedRowKeys([]);
    }
  }, [reset]);
  
  useEffect(() => {
    if (!!paramsProp) {
      setParams(paramsProp);
      setPage(1);
    }
  }, [paramsProp]);
  
  useEffect(() => {
    mutate();
  }, [refresh]);
  
  useEffect(() => {
    const column: any[] = [];
    if (!!header) {
      header.forEach((h, index) => {
        const isDate = h.includes("time");
        const isLong = h.includes("address1") || h.startsWith("product");
        const onSortAsc = () => {
          return handleSortAsc(h);
        };
        
        const onSortDesc = () => {
          return handleSortDesc(h);
        };
        column.push({
          title: (<TableCell
            className={"bold table-column"}
            value={t(h)}
            sort={!!sortColumn && h.includes(sortColumn) ? {
              onSortAsc, onSortDesc,
            } : undefined}
          />), dataIndex: h, width: isLong ? 350 : undefined, key: h, render: (text: string, all: number) => (<TableCell
            key={"tcell" + index}
            className={"table-column " + (index === 0 && " link")}
            refreshing={refreshing}
            value={isDate && text ? format(new Date(text), "yyyy.MM.dd HH:mm:ss") : t(text)}
            onClick={!!onLink && index === 0 ? () => onLink(all) : undefined}
          />),
        });
      });
      setColumns(column);
    }
  }, [header, refreshing]);
  
  const handleSortAsc = useCallback((sortColumn: string) => {
    const order = {ordering: sortColumn};
    setParams((prev: any) => (!!prev ? {...prev, ...order} : order));
  }, []);
  
  const handleSortDesc = useCallback((sortColumn: string) => {
    const order = {ordering: "-" + sortColumn};
    setParams((prev: any) => (!!prev ? {...prev, ...order} : order));
  }, []);
  
  const handleChangeRow = useCallback((keys: any, rows: any) => {
    console.log("handleChangeRow", keys, rows);
    setSelectedRowKeys(keys);
    if (onChange) onChange(rows, keys, page);
  }, [onChange]);
  
  const handleSelectRow = useCallback((record: any, selected: any, unk: any, unk2: any) => {
    console.log("handleSelectRow", record, selected, unk, unk2);
    if (onSelect) onSelect(record);
  }, [onSelect]);
  
  return (<div className={"table-container"} style={style}>
    <Table
      scroll={scrollX ? {
        x: scrollX, scrollToFirstRowOnChange: false, y: scrollY,
      } : {x: "max-content"}}
      rowSelection={onChange ? {
        selectedRowKeys,
        onChange: handleChangeRow,
        onSelect: handleSelectRow,
        preserveSelectedRowKeys: false, ...rowSelection,
      } : undefined}
      style={tableStyle}
      className={"table " + className}
      columns={columnsProp || columns}
      dataSource={refreshing ? emptyData : data}
      locale={locale}
      pagination={dataProp && dataProp.length > 0 && pagination ? {
        defaultPageSize, className: "paginationStyle", position: ["bottomRight"],
      } : false}
    />
    {!(dataProp && dataProp?.length > 0) && url && pagination && (<Pagination
      defaultCurrent={page}
      current={page}
      total={urlData?.count}
      className={"paginationStyle " + paginationClass}
      showSizeChanger
      defaultPageSize={pageSize}
      onShowSizeChange={(current: number, size: number) => setPageSize(size)}
      onChange={(page: number) => setPage(page)}
    />)}
  </div>);
});

export default TableView;
