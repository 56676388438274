import React, { useCallback, useEffect, useRef, useState } from "react";
import "./shippingMap.css";
import TileLayer from "ol/layer/Tile";
import { useTranslation } from "react-i18next";
import {
  GetShippingAPIParams2,
  ShippingItemListType,
} from "../../../api/apiTypes";
import { XYZ } from "ol/source";
import { revertShipping } from "../../../api/shippingApi";
import { showApiError, showMessageWithTitle } from "../../../fns/message";
import SectorChangePopup, {
  TrackingType,
} from "../../organisms/sectorChange/SectorChangePopup";
import { THoveredShipping } from "../../organisms/shippingMap/ShippingsTooltip";
import SellerFilter from "../../organisms/shippingMap/SellerFilter";
import { Button, Input, Space } from "antd";
import SectorFilter from "../../organisms/shippingMap/SectorFilter";
import { CustomBreadcrumb } from "@delivus/daas-leftmenu-template";
import styles from "../../organisms/searchView/search.module.css";
import magnify from "../../../assets/svgs/magnify.svg";
import SelectedShippings from "../../organisms/shippingMap/SelectedShippings";
import PopupSpinner from "../../molecules/spinner/PopupSpinnerView";
import ContainerChangePopup from "../../organisms/containerChange/ContainerChangePopup";
import SectorNameFilter from "../../organisms/shippingMap/SectorNameFilter";
import { Coordinate } from "ol/coordinate";

import SellerBatchFilter from "../../organisms/shippingMap/SellerBatchFilter";
import TimeFilter from "../../organisms/shippingMap/TimeFilter";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import ShippingMap from "../../organisms/shippingMap/ShippingMap";
export type SectorColorType = {
  sector?: string;
  color: string;
};

export type HoveredShippingTooltipType = {
  shippings?: THoveredShipping[];
  position?: Coordinate;
};
export const vworldBaseLayer = new TileLayer({
  source: new XYZ({
    url: `http://api.vworld.kr/req/wmts/1.0.0/${process.env.REACT_APP_MAP_KEY}/white/{z}/{y}/{x}.png`,
  }),
  properties: { name: "base-vworld-base" },
  minZoom: 5,
  maxZoom: 19,
  // zIndex: 2,
  preload: Infinity,
});
const ShippingMapScreen = () => {
  const { t } = useTranslation(["shipping"]);
  const { t: tCommon } = useTranslation(["common"]);
  const mapRef = useRef<any>();
  const sectorChangeRef = useRef<any>();
  const sectorFilterRef = useRef<any>();
  const containerChangeRef = useRef<any>();
  const paramsRef = useRef<GetShippingAPIParams2>();
  const selectedShippingsRef = useRef<ShippingItemListType[]>([]);
  const [selectedShippings, setSelectedShippings] = useState<
    ShippingItemListType[]
  >([]);
  const [params, setParams] = useState<GetShippingAPIParams2>({});
  const [isReverting, setIsReverting] = useState(false);

  useEffect(() => {
    paramsRef.current = params; //handlegetsector info에서 사용됨
  }, [params]);

  const handleFilterBySector = useCallback(
    (optionValue: string[]) => {
      let param = params;
      if (!params) param = {};
      const { pickup_round, ...rest } = param;
      param = { ...rest };
      param.designated_sector__code__in = optionValue;
      setParams(param);
      selectedShippingsRef.current = [];
    },
    [params]
  );

  const handleFilterBySectorName = useCallback(
    (optionValues: any[]) => {
      let param = params;
      if (!params) param = {};
      const { pickup_round, ...rest } = param;
      param = { ...rest };
      param.designated_sector__name__in = optionValues;
      setParams(param);
      selectedShippingsRef.current = [];
    },
    [params]
  );

  const handleFilterByMinute = (start: number, end: number) => {
    let param = params;
    if (!params) param = {};
    const { shipping_timestamp_difference_in_minute, ...rest } = param;
    param = { ...rest };
    param.shipping_timestamp_difference_in_minute = start;
    param.shipping_timestamp_difference_end_in_minute = end;
    setParams(param);
    selectedShippingsRef.current = [];
  };

  const handleFilterByBatch = useCallback(
    (optionValue: any[]) => {
      let param = params;
      if (!params) param = {};
      const { pickup_batch__uuid__in, ...rest } = param;
      param = { ...rest };
      param.pickup_batch__uuid__in = optionValue;
      setParams(param);
      selectedShippingsRef.current = [];
    },
    [params]
  );

  const handleFilterByShop = useCallback(
    (optionValue: any[]) => {
      let param = params;
      if (!params) param = {};
      const { shipping_shop__uuid__in, ...rest } = param;
      param = { ...rest };
      param.shipping_shop__uuid__in = optionValue;
      setParams(param);
      selectedShippingsRef.current = [];
    },
    [params]
  );

  const handleFilterByReturnShop = useCallback(
    (optionValue: any[]) => {
      let param = params;
      if (!params) param = {};
      const { return_order__shop__uuid__in, ...rest } = param;
      param = { ...rest };
      param.return_order__shop__uuid__in = optionValue;
      setParams(param);
      selectedShippingsRef.current = [];
    },
    [params]
  );

  const handleResetSelectedShippings = useCallback(() => {
    if (mapRef.current) {
      mapRef.current.reset();
    }
    setSelectedShippings([]);
  }, []);

  const handleSectorChanged = () => {
    handleResetSelectedShippings();
    showMessageWithTitle(
      t("popup.title"),
      t("popup.success.change.sector"),
      "info",
      t("popup.btn.ok")
    );
    if (mapRef.current) mapRef.current.refresh();
    if (sectorFilterRef.current) sectorFilterRef.current.mutate();
  };

  const handleContainerChanged = () => {
    handleResetSelectedShippings();
    showMessageWithTitle(
      t("popup.title"),
      t("popup.success.change.container"),
      "info",
      t("popup.btn.ok")
    );
    if (mapRef.current) mapRef.current.refresh();
    if (sectorFilterRef.current) sectorFilterRef.current.mutate();
  };

  function createTrackingList() {
    const trackingList: TrackingType[] = [];
    selectedShippings &&
      selectedShippings.forEach(({ tracking_number }) => {
        trackingList.push({ tracking_number });
      });
    console.log("createTracking", trackingList);
    return trackingList;
  }

  const handleSearch = useCallback(
    (e: any) => {
      let param = params;
      if (!params) param = {};
      param.search = e.target.value;
      setParams(param);
      selectedShippingsRef.current = [];
    },
    [params]
  );

  const handlChangeContainer = () => {
    if (!!selectedShippings && selectedShippings.length > 0) {
      containerChangeRef.current.show(createTrackingList());
    } else {
      showMessageWithTitle(
        t("popup.title"),
        t("popup.error.empty"),
        "info",
        t("popup.btn.ok")
      );
    }
  };

  const handleChangeSector = () => {
    if (!!selectedShippings && selectedShippings.length > 0) {
      sectorChangeRef.current.show(createTrackingList());
    } else {
      showMessageWithTitle(
        t("popup.title"),
        t("popup.error.empty"),
        "info",
        t("popup.btn.ok")
      );
    }
  };

  const handleRevertStatus = () => {
    if (!!selectedShippings && selectedShippings.length > 0) {
      setIsReverting(true);
      revertShipping(
        createTrackingList(),
        () => {
          showMessageWithTitle(
            t("popup.title"),
            t("popup.success.revert.status"),
            "info",
            t("popup.btn.ok")
          );
          setIsReverting(false);
          if (mapRef.current) mapRef.current.refresh();
        },
        (err) => {
          setIsReverting(false);
          showApiError(err);
        }
      );
    } else {
      showMessageWithTitle(
        t("popup.title"),
        t("popup.error.empty"),
        "info",
        t("popup.btn.ok")
      );
    }
  };

  const buttons = [
    {
      title: t("shipping.status.btn.change.box"),
      onClick: handlChangeContainer,
    },
    {
      title: t("shipping.status.btn.change.sector"),
      onClick: handleChangeSector,
    },
    {
      title: t("shipping.status.btn.change.status"),
      onClick: handleRevertStatus,
    },
  ];

  const buttonNode = buttons && (
    <Space size={1}>
      {buttons.map((item, idx) => {
        return (
          <Button
            key={"searchbtn" + idx}
            type={"primary"}
            size={"small"}
            className={"search-btn"}
            onClick={item.onClick}
          >
            {item.title}
          </Button>
        );
      })}
    </Space>
  );

  const inputNode = (
    <form
      className={"row flex shiping-search " + styles.searchInputCntr}
      onSubmit={handleSearch}
    >
      <Input
        defaultValue={""}
        autoComplete={"off"}
        size={"small"}
        className={styles.searchInput}
        maxLength={30}
        placeholder={t("search.place")}
      />
      <Button
        data-cy="searchHomeInput"
        className={styles.searchBtn}
        onClick={handleSearch}
        icon={<img alt={"shipping-map"} src={magnify} className={styles.searchIcon} />}
      ></Button>
    </form>
  );

  const renderHeader = () => (
    <div className={"row align-center shipping-header"}>
      <CustomBreadcrumb t={tCommon} />
      <span className={"row control"}>
        {inputNode}
        {buttonNode}
      </span>
    </div>
  );

  const renderFilter = () => (
    <div className={"row filter-cntr"}>
      <div className={"column flex"}>
        <span className={"body black85 filterTitle "}>{t("shop")}</span>
        <TimeFilter
          start={params.shipping_timestamp_difference_in_minute}
          end={params.shipping_timestamp_difference_end_in_minute}
          onSearch={handleFilterByMinute}
        />
        <SellerFilter
          value={params.shipping_shop__uuid__in}
          onSearch={handleFilterByShop}
        />
      </div>
      <div className={"filter-divider"} />
      <div className={"column flex"}>
        <span className={"body black85 filterTitle "}>{t("batch")}</span>
        <SellerBatchFilter
          value={params.pickup_batch__uuid__in}
          onSearch={handleFilterByBatch}
        />
      </div>
      <div className={"filter-divider"} />

      <div className={"column flex"}>
        <span className={"body black85 filterTitle "}>{t("return shop")}</span>
        <SellerFilter
          value={params.return_order__shop__uuid__in}
          onSearch={handleFilterByReturnShop}
        />
      </div>
      <div className={"filter-divider"} />

      <div className={"column flex"}>
        <span className={"body black85 filterTitle "}>{t("sector code")}</span>
        <SectorFilter
          ref={sectorFilterRef}
          params={params}
          value={params.designated_sector__code__in}
          disabled={
            !!params.designated_sector__name__in &&
            params.designated_sector__name__in.length > 0
          }
          onSearch={handleFilterBySector}
        />
      </div>
      <div className={"filter-divider"} />

      <div className={"column flex"}>
        <span className={"body black85 filterTitle "}>{t("sector area")}</span>
        <SectorNameFilter
          value={params.designated_sector__name__in}
          disabled={
            !!params.designated_sector__code__in &&
            params.designated_sector__code__in.length > 0
          }
          onSearch={handleFilterBySectorName}
        />
      </div>
      <div className={"filter-divider"} />

      <div className={"column flex"}>
        <SelectedShippings
          data={selectedShippings}
          onReset={handleResetSelectedShippings}
        />
      </div>
    </div>
  );

  return (
    <LeftTitledTemplate classname={"shipping-map-container"}>
      {renderHeader()}
      <div className={"shipping-map column"}>
        {renderFilter()}
        <div className={"flex"}>
          <ShippingMap
            ref={mapRef}
            params={params}
            onRefresh={() => setParams({})}
            onSelectShippings={setSelectedShippings}
          />
        </div>
        <SectorChangePopup
          ref={sectorChangeRef}
          onSplitted={handleSectorChanged}
        />
        <ContainerChangePopup
          ref={containerChangeRef}
          onSplitted={handleContainerChanged}
        />
        {isReverting && <PopupSpinner />}
      </div>
    </LeftTitledTemplate>
  );
};

export default ShippingMapScreen;
