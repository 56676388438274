import React, {RefObject, useCallback, useRef, useState} from "react";
import "./list.style.css";
import {Trans, useTranslation} from "react-i18next";
import {CheckoutBoxApiParams, ContainerBoxListApiParams, LogicalBoxType,} from "../../../api/apiTypes";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import {isDesktopByWidth} from "../../../fns/commonFns";
import TableView, {CustomTableRef} from "../../molecules/table/TableView";
import PenSvg from "../../../assets/svgs/pen.svg";
import styles from "../containerManage/containerManage.module.css";
import {Button} from "antd";
import {isDesktop} from "react-device-detect";
import {BoxLabelFormat} from "@delivus/daas-print-lib";
import {showApiError, showMessage, showMessageWithTitle,} from "../../../fns/message";
import PrintView from "../../molecules/print/PrintView";
import TableCell from "../../molecules/table/TableCell";
import InputPopup from "../../organisms/InputPopup";
import {checkoutBatchBox, deleteContainerByUuid, updateContainer,} from "../../../api/shippingApi";
import {useNavigate} from "react-router-dom";

import SearchView, {SearchResultType,} from "../../organisms/searchView/SearchView";
import TypeSelector from "../../organisms/typeSelector/TypeSelector";
import TypeItem from "../../organisms/typeSelector/TypeItem";
import TabbarView from "../../molecules/tabbar/TabbarView";
import {useSectorOptions} from "../../../api/useSectorOptions";
import PopupSpinner from "../../molecules/spinner/PopupSpinnerView";

const BoxListScreen = () => {
  const {t} = useTranslation(["box"]);
  const inputPopupRef = useRef<any>();
  const selectedRowsRef = useRef<LogicalBoxType[]>();
  const dataRef = useRef<LogicalBoxType[]>();
  const printRef = useRef<any>();
  const tableRef = useRef() as RefObject<CustomTableRef>;
  const navigate = useNavigate();
  const [params, setParams] = useState<ContainerBoxListApiParams>();
  const [loading, setLoading] = useState(true);
  const [isUpdating, setUpdating] = useState(false);
  const {sectorOptions} = useSectorOptions(t)
  
  const handleSelect = useCallback((data: any[]) => {
    selectedRowsRef.current = data;
  }, []);
  
  const handleGetCount = useCallback((count: number, data: any[]) => {
    dataRef.current = data;
    setLoading(false);
  }, []);
  
  const handleSearch = ({inputValue, selectedOption}: SearchResultType) => {
    let options: ContainerBoxListApiParams = {box__alias: inputValue};
    if (selectedOption !== "all") {
      options.sector__code = selectedOption;
    }
    setParams(options);
    setLoading(true);
  };
  
  const handleEditCount = (id: string, count_box: string) => {
    setUpdating(true);
    updateContainer(id, {count_box})
    .then(() => {
      if (tableRef.current) tableRef.current.requestRefresh();
      showMessageWithTitle(t("box.item.popup.print.success.title"), t("popup.success.box.edited"));
    })
    .catch(showApiError)
    .finally(() => {
      setUpdating(false);
    })
  };
  
  const handlePrintDamaged = useCallback((alias?: string, unit_storage_name?: string) => {
    if (isDesktop) {
      printRef.current.show();
      const format = (<BoxLabelFormat
        alias={alias || ""}
        unit_storage_name={unit_storage_name || ""}
      />);
      printRef.current.setContent(format, true);
    }
    else {
      showMessage(t("box.item.popup.print.error.notdesktop"));
    }
  }, []);
  
  const handlePrint = useCallback((data?: LogicalBoxType[]) => {
    const array = !!data && data.length > 0 ? data : selectedRowsRef.current;
    selectedRowsRef.current = array;
    if (!!array && array.length > 0) {
      const invoivesNode = array.map(({box, container}, index: number) => (<BoxLabelFormat
        key={"print" + index}
        alias={box?.alias || ""}
        unit_storage_name={container?.unit_storage || ""}
      />));
      if (!!printRef.current) {
        printRef.current.setContent(invoivesNode);
      }
    }
    else {
      showMessageWithTitle(t("box.item.popup.print.success.title"), t("error.popup.box.print.notselected"), "warning");
    }
  }, []);
  
  const handleDeleteContainer = () => {
    if (selectedRowsRef.current && selectedRowsRef.current?.length === 1 && selectedRowsRef.current[0].container?.uuid) {
      setUpdating(true);
      deleteContainerByUuid(selectedRowsRef.current[0].container.uuid)
      .then(() => {
        if (tableRef.current) tableRef.current.requestRefresh();
        showMessage(t("error.popup.box.delete.uuid"));
      })
      .catch(showApiError)
      .finally(() => {
        setUpdating(false);
      })
    }
  };
  
  const handleClose = (data?: LogicalBoxType[]) => {
    const array = !!data && data.length > 0 ? data : selectedRowsRef.current;
    if (!!array && array.length > 0) {
      setUpdating(false);
      const uuidList: CheckoutBoxApiParams = [];
      array.forEach((s: LogicalBoxType) => {
        if (s.box?.uuid) uuidList.push({box_uuid: s.box?.uuid});
      });
      checkoutBatchBox(uuidList)
      .then(() => {
        if (array.length === 1) showMessage(t("popup.checkout.done")); else showMessage(t("popup.checkout.all.done"));
      })
      .catch(showApiError)
      .finally(() => {
        setUpdating(false);
      });
    }
    else {
      showMessageWithTitle(t("box.item.popup.print.success.title"), t("error.popup.box.print.notselected"), "warning");
    }
  };
  
  const onPrinted = (damaged?: boolean) => {
    console.log("handleAfterPrint", damaged, selectedRowsRef.current);
    window.onfocus = null;
    if (damaged) {
      showMessageWithTitle(t("box.item.popup.print.success.title"), t("box.item.popup.print.success.content"));
    }
    else {
      const array = !!selectedRowsRef.current && selectedRowsRef.current.length > 0
        ? selectedRowsRef.current
        : dataRef.current;
      if (array) {
        const promises = array.map(({container}) => container && updateContainer(container.uuid, {count_box: (container.count_box || 0) + 1}));
        Promise.all(promises)
               .then(() => {
                 if (tableRef.current) tableRef.current.requestRefresh();
                 showMessageWithTitle(t("box.item.popup.print.success.title"), t("box.item.popup.print.success.content"));
               })
               .catch(showApiError)
               .finally(() => {
                 setUpdating(false);
               });
      }
    }
  };
  
  const navigateDetail = (boxUuid?: string, containerUuid?: string) => {
    navigate(`/home/list/detail/${boxUuid}/${containerUuid}`);
  };
  
  const handleEditBox = (uuid?: string, plan?: any) => {
    setUpdating(true)
    if (uuid) {
      updateContainer(uuid, {is_plan: plan === "plan"})
      .then(() => {
        if (tableRef.current) tableRef.current.requestRefresh();
        showMessage(t("success.popup.box.edit"));
      })
      .catch(showApiError)
      .finally(() => {
        setUpdating(false);
      });
    }
  };
  
  const columns = [
    {
      title: <div className={"bold body"}>{t("manage.table.box")}</div>,
      width: 135,
      dataIndex: "box",
      key: "box",
      render: (text: string, p: LogicalBoxType) => (<TableCell
        refreshing={loading}
        className={"body underline primary"}
        onClick={() => navigateDetail(p?.box?.uuid, p.container?.uuid)}
      >
        {p.box?.alias || "-"}
      </TableCell>),
    }, {
      title: <div className={"bold body"}>{t("box.item.col.scan")}</div>,
      width: 162,
      dataIndex: "type",
      key: "type",
      render: (text: string, p: LogicalBoxType) => (<TableCell className={" body "} refreshing={loading}>
        <Trans
          i18nKey={"list.item.count"}
          ns={"box"}
          values={{
            return: p?.container?.checked_in_return_item_count || 0,
            shipping: p?.container?.checked_in_shipping_item_count || 0,
          }}
          components={{
            red: <span className={" errorActive"} />,
          }}
        />
      </TableCell>),
    }, {
      title: <div className={"bold body"}>{t("box.item.col.state")}</div>,
      dataIndex: "count",
      key: "count",
      width: 90,
      render: (text: string, p: LogicalBoxType) => (<TableCell refreshing={loading} className={"row black85"}>
        {!!p.container?.status ? t("list.item." + p.container?.status) : "-"}
      </TableCell>),
    }, {
      title: (<TableCell className={"bold body"} value={t("manage.table.type")} />),
      width: 90,
      dataIndex: "rider",
      render: (text: string, p: LogicalBoxType) => (<TableCell refreshing={loading}>
        <TypeSelector
          selectType={p?.container?.is_plan ? "plan" : "gen"}
          values={[
            {
              key: "gen", title: <TypeItem type={"PLAN"} title={t("col.gen")} />,
            }, {
              key: "plan", title: <TypeItem type={"PLAN"} is_plan title={t("col.plan")} />,
            },
          ]}
          onSelect={(is_plan) => handleEditBox(p?.container?.uuid, is_plan)}
        />
      </TableCell>),
    }, {
      title: <div className={"bold body"}>{t("box.item.col.count")}</div>,
      dataIndex: "count",
      key: "count",
      render: (text: string, p: LogicalBoxType) => (<TableCell refreshing={loading} className={"row black85"}>
        {p.container?.count_box}
        <Button
          type={"text"}
          onClick={() => inputPopupRef.current.show(p.container?.uuid, p.container?.count_box)}
          className={"box-item-btn-edit"}
          icon={<img className={"box-item-img"} src={PenSvg} />}
        />
      </TableCell>),
    }, {
      title: <div className={"bold body"}>{t("box.item.col.damaged")}</div>,
      width: 141,
      dataIndex: "change",
      key: "change",
      render: (text: string, p: LogicalBoxType) => (<Button
        type={"primary"}
        className={styles.cellBtn + " body "}
        onClick={() => handlePrintDamaged(p.box?.alias, p.container?.unit_storage)}
      >
        {t("box.item.col.damaged")}
      </Button>),
    }, {
      title: <div className={"bold body"}>{t("box.item.col.label")}</div>,
      width: 141,
      dataIndex: "lost",
      key: "lost",
      render: (text: string, p: LogicalBoxType) => (<Button
        type={"primary"}
        onClick={() => handlePrint([p])}
        className={styles.cellBtn + " body "}
      >
        {t("box.item.btn.label.count", {count: p.container?.count_box})}
      </Button>),
    },
  ];
  
  const buttons = [
    {title: t("box.reg.close.all"), onClick: () => handleClose(dataRef.current)},
    {title: t("box.reg.close"), onClick: () => handleClose()},
    {title: t("box.reg.print.all"), onClick: () => handlePrint(dataRef.current)},
    {title: t("box.reg.print"), onClick: () => handlePrint()},
    {title: t("container_checkin_button_cancel"), onClick: handleDeleteContainer},
  ];
  
  const containerItems: any = [
    {
      label: t("container_type_all"), key: t("container_type_all"),
    }, {
      label: t("container_type_plan"), key: t("container_type_plan"),
    }, {
      label: t("container_type_manual"), key: t("container_type_manual"),
    },
  ];
  
  const containerParamsUpdate = (v: string) => {
    let pt: any = {...params};
    if (v === t("container_type_all")) {
      if ("is_plan" in pt) {
        delete pt["is_plan"];
        setParams(pt);
        setLoading(true);
      }
    }
    else if (v === t("container_type_plan")) {
      pt.is_plan = true;
      setParams(pt);
      setLoading(true);
    }
    else if (v === t("container_type_manual")) {
      pt.is_plan = false;
      setParams(pt);
      setLoading(true);
    }
  };
  
  return (<>
    <LeftTitledTemplate classname={" box-list-container "} showBreadTitle>
      <SearchView
        container={containerItems}
        containerSelect={containerParamsUpdate}
        options={sectorOptions}
        onSearch={handleSearch}
        buttons={buttons}
      />
      <TableView
        ref={tableRef}
        url={"/v1/hub/containers/boxes/"}
        params={params}
        scrollX={window.innerWidth - 200}
        scrollY={"calc(var(--app-height) - var(--navbar-height) - var(--tab-height) - 225px)"}
        onChange={handleSelect}
        columns={columns}
        tableStyle={{
          height: isDesktopByWidth() ? window.innerHeight - 320 : window.innerHeight - 130,
        }}
        style={{
          height: isDesktopByWidth() ? window.innerHeight - 260 : window.innerHeight - 130, overflowY: "scroll",
        }}
        onGetData={handleGetCount}
        t={t}
      />
      {isUpdating && <PopupSpinner />}
      <PrintView
        ref={printRef}
        onPrinted={onPrinted}
        btnOk={t("box.item.btn.label")}
      />
      <InputPopup
        ref={inputPopupRef}
        onOK={handleEditCount}
        title={t("popup.title.box.count")}
      />
    </LeftTitledTemplate>
    <TabbarView />
  </>);
};

export default BoxListScreen;
