import http from "../services/http.service";
import {
  GenerateTokeApiResponse,
  GetListResponse,
  GetLocationStsApiResponse,
  GetSectorApiResponse,
  UnitItemType,
} from "./apiTypes";
import {ErrorType, showApiError} from "../fns/message";
import { AxiosError } from "axios";

export const generateUnitQR = (
  id: string,
  onSucceed: (data: GenerateTokeApiResponse) => void,
  onError: (err: AxiosError<ErrorType>) => void
) => {
  http
    .get(`/v1/location/unitstorage/${id}/qr/`)
    .then((res) => {
      console.log("generateUnitQR", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const generateHubQR = (
  id: string,
  onSucceed: (data: GenerateTokeApiResponse) => void,
  onError: (err: AxiosError<ErrorType>) => void
) => {
  http
    .get(`/v1/location/mainhubs/${id}/qr/`)
    .then((res) => {
      console.log("generateHubQR", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const getLocationSts = (
  onSucceed: (res: GetLocationStsApiResponse) => void
) => {
  http
    .get(`/v1/location/stat/`)
    .then((res) => {
      console.log("getLocationSts", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const getUnitStorages = (
  onSucceed: (res: GetListResponse<UnitItemType>) => void
) => {
  http
    .get(`/v1/location/unitstorages/?page_size=50`)
    .then((res) => {
      console.log("getUnitStorages", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const getUnitContainerStatus = (id: string) =>
  http.get(`/v1/unit/units/${id}/stat/containers/`);
