import {Breadcrumb} from "antd";
import React, {useMemo} from "react";
import "./breadcrumb.css";
import {useLocation} from "react-router-dom";

type BreadcrumbProps = {
    t: (text: string) => string;
    paths?: string[];
    onClickLink?: (path: string) => void;
};
export const CustomBreadcrumb = ({
                                     t,
                                     paths: pathsProp,
                                     onClickLink,
                                 }: BreadcrumbProps) => {
    const location = useLocation();

    const paths = useMemo(() => {
        let breadPaths: string[] = [];
        if (!!pathsProp) {
            breadPaths = pathsProp;
        } else {
            let paths = location.pathname.split("/").filter((p) => p.length > 0);
            for (let i = 0; i < paths.length; i++) {
                let path = paths[i];
                for (let j = 0; j < i; j++) {
                    path = paths[j] + "." + path;
                }
                breadPaths.push(path);
            }
        }
        return breadPaths;
    }, [location, pathsProp]);

    function handleClick(path: string) {
        return function () {
            !!onClickLink && onClickLink(path);
        };
    }

    return (
        <Breadcrumb separator=">">
            {paths &&
                paths.map((path: string, index: number) => (
                    <Breadcrumb.Item
                        data-cy="customBreadCrumbItem"
                        key={"breaditem" + index}
                        className={onClickLink ? "link" : ""}
                        onClick={onClickLink ? handleClick(path) : undefined}
                    >
                        {t(path)}
                    </Breadcrumb.Item>
                ))}
        </Breadcrumb>
    );
};
