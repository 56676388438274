import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { checkinReturnItem } from "../../api/shippingApi";
import { ShippingType } from "../../api/apiTypes";
import { AxiosError } from "axios";
import ScanView from "../organisms/scanView/ScanView";
import { getApiError } from "../../fns/objectFns";
import TabbarView from "../molecules/tabbar/TabbarView";
import { ErrorType } from "../../fns/message";

const ScanReturnItemScreen = () => {
  let resetForm: () => void;
  const { t } = useTranslation(["scan"]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState<ShippingType>();
  const [loading, setLoading] = useState(false);

  const handleCheckin = ({ box_id }: { box_id: string }) => {
    console.log("onScan", box_id);
    if (box_id && !loading) {
      setLoading(true);
      checkinReturnItem(box_id, onCheckingSuccess, onCheckingFail);
    }
  };

  const handleRefresh = () => {
    setError("");
    setSuccess(undefined);
  };

  const onCheckingSuccess = (res: ShippingType) => {
    setLoading(false);
    setSuccess(res);
    if (!!resetForm) {
      resetForm();
    }
    setTimeout(handleRefresh, 2000);
  };

  const onCheckingFail = (err: AxiosError<ErrorType>) => {
    console.log("scanerror return", resetForm);
    setLoading(false);
    setSuccess(undefined);
    setError(getApiError(err));
    if (!!resetForm) {
      resetForm();
    }

    setTimeout(handleRefresh, 2000);
  };

  return (
    <>
      <ScanView
        resetRef={(c) => {
          resetForm = c;
        }}
        onScan={handleCheckin}
        onRefresh={handleRefresh}
        error={error}
        success={success?.alias}
        successLabel={t("return.scan.done")}
        info={t("return.scan.info")}
      />
      <TabbarView />
    </>
  );
};

export default ScanReturnItemScreen;
