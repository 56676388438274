import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import {useTranslation} from "react-i18next";
import {
    GetListResponse,
    IClusterPlanLongItem,
    IGetPlanLongAPIParam,
} from "../../../api/apiTypes";
import TableCell from "../../molecules/table/TableCell";
import {queries} from "../../../api/queries";
import {InfiniteTable} from "../../molecules/table/InfiniteTable";
import {objToQueryString} from "../../../fns/commonFns";
import http from "../../../services/http.service";
import styles from "./plan.module.css";
import {showApiError} from "../../../fns/message";

type Props = {
    params: IGetPlanLongAPIParam;
    onSelectData: (data: IClusterPlanLongItem[]) => void;
};

const PAGE_SIZE = 20;
const PlanTable = forwardRef(({params, onSelectData}: Props, ref) => {
    const {t} = useTranslation(["shipping"]);
    const tableRef = useRef<any>();
    const wrapperRef = useRef<any>();
    const hasNext = useRef(true);
    const hasLoaded = useRef(true);
    const selectedRowKeysRef = useRef<string[]>();
    const selectedRowsRef = useRef<IClusterPlanLongItem[]>();
    const defaultCheckAll = useRef(false);
    const releaseCheckAll = useRef(false);

    const timerRef = useRef<any>();
    const [isLoading, setLoading] = useState(false);
    const page = useRef(0);
    const lastLoadedPage = useRef(0);
    const dataRef = useRef<any[]>([]);

    const fetchData = (pageIndex: number, {plan_date, ...rest}: IGetPlanLongAPIParam) => {
        console.log("table fetchData requ", pageIndex, page, params);
        if (page.current !== pageIndex && hasLoaded.current) {
            hasLoaded.current = false;
            setLoading(true);
            const paramString = objToQueryString({plan_date, ...rest}, true);
            console.log("table fetchData requ", paramString);
            http.get(
                    `${queries.plan.long}?page=${pageIndex}&page_size=${PAGE_SIZE}&${paramString}`
                )
                .then((res) => {
                    const data: GetListResponse<IClusterPlanLongItem> = res.data;
                    console.log("table fetchData lastLoadedPage:", lastLoadedPage.current, "page:", pageIndex);
                    if (data) {
                        hasNext.current = !!data.next;
                        if (pageIndex > 1)
                            dataRef.current = dataRef.current.concat(data.results);
                        else dataRef.current = data.results || [];
                        page.current = pageIndex;
                        hasLoaded.current = true;
                        if (!hasNext.current || pageIndex >= lastLoadedPage.current) {
                            lastLoadedPage.current = pageIndex;
                            if(tableRef.current && dataRef.current) {
                                tableRef.current.setDataSource(dataRef.current, defaultCheckAll.current, releaseCheckAll.current);
                            }
                            setLoading(false);
                        } else {
                            fetchData(pageIndex + 1, {plan_date, ...rest})
                        }
                    }
                })
                .catch((e) => {
                    showApiError(e);
                    setLoading(false);
                })
                .finally(() => {
                    hasLoaded.current = true;
                });
        }
    };

    const handleScrollEnd = useCallback(() => {
        console.log("table handleScrollEnd", hasNext, page.current);
        if (hasNext.current) {
            fetchData(page.current + 1, params);
        }
    }, [params, fetchData]);

    const requestRefresh = useCallback((defaultCheck?: boolean, releaseCheck?: boolean) => {
        console.log("table requestRefresh", params);
        page.current = 0;
        hasNext.current = true;
        defaultCheckAll.current = !!defaultCheck;
        releaseCheckAll.current = !!releaseCheck;
        fetchData(1, params);
    }, [params]);

    useEffect(() => {
        console.log("table useEffect params");
        timerRef.current && clearTimeout(timerRef.current);
        lastLoadedPage.current = 0;
        if (!params.plan_no) {
            timerRef.current = setTimeout(() => {
                requestRefresh(!!params.cluster_id_startswith_in_csv?.length);
            }, 1000);
        } else requestRefresh(!!params.cluster_id_startswith_in_csv?.length);

    }, [params]);

    useImperativeHandle(
        ref,
        () => ({
            requestRefresh
        }),
        [params]
    );

    const columns = [
        {
            title: <TableCell className={"bold small nowrap"} value={t("cluster id")}/>,
            render: (text: string, p: IClusterPlanLongItem) => (
                <TableCell refreshing={false} className={"small black-cl "}>
                    <span className={styles.planCluster + " " + styles.planCell}>{p.cluster_id || "-"}</span>
                </TableCell>
            ),
        },
        {
            title: <TableCell className={"bold small nowrap"} value={t("bunny type")}/>,
            width: 80,
            render: (text: string, p: IClusterPlanLongItem) => {
                const bunnyColor = String(p.bunny_color).toUpperCase();
                return (
                    <TableCell refreshing={false} className={"small "}>
            <span className={styles.bunnyType + " " + styles[bunnyColor]}>
              {bunnyColor || "-"}
            </span>
                    </TableCell>
                );
            },
        },
        {
            title: <TableCell className={"bold small nowrap"} value={t("chute id")}/>,
            width: 60,
            render: (text: string, p: IClusterPlanLongItem) => (
                <TableCell refreshing={false} className={"small black-cl center"}>
                    {p?.chute_id}
                </TableCell>
            ),
        },
        {
            title: <TableCell className={"bold small nowrap"} value={t("shipping count")}/>,
            width: 80,
            render: (text: string, p: IClusterPlanLongItem) => (
                <TableCell refreshing={false} className={"small black-cl center"}>
                    {`${p?.count_scanned_all} / ${p?.shipping_item_count}`}
                </TableCell>
            ),
        }
    ];

    const handleChangeRow = useCallback(
        (data: any[], keys: any[]) => {
            selectedRowKeysRef.current = keys;
            selectedRowsRef.current = data;
            onSelectData(data);
        },
        []
    );

    return (
        <div ref={wrapperRef} className={styles.planTable}>
            <InfiniteTable
                ref={tableRef}
                columns={columns}
                page={page.current}
                onScrollEnd={handleScrollEnd}
                onChange={handleChangeRow}
                t={t}
                rowKey={"id"}
                isLoading={isLoading}
                defaultPageSize={10}
                scrollX={'100%'}
                scrollEndY={330}
                scrollY={"calc(var(--app-height) - var(--navbar-height) - 302px - 96px)"}
            />
        </div>
    );
});

export default PlanTable;
