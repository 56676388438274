import React from "react";
import {Navigate} from "react-router-dom";

function AuthRouteComponent({
                                children,
                                isLoggedIn,
                            }: any) {
    if(!isLoggedIn) {
        return (
            <Navigate to="/main/guide" replace/>
        );
    }
    return children;
}

export default AuthRouteComponent;
