import { useReactToPrint } from "react-to-print";
// browser detection
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const copyStyles = (domDoc: any) => {
  const nonce = "";
  const headEls = document.querySelectorAll("style, link[rel='stylesheet']");

  for (let i = 0, headElsLen = headEls.length; i < headElsLen; ++i) {
    const node = headEls[i];
    if (node.tagName === "STYLE") {
      const newHeadEl = domDoc.createElement(node.tagName);
      const sheet = (node as HTMLStyleElement).sheet as CSSStyleSheet;

      if (sheet) {
        let styleCSS = "";
        // NOTE: for-of is not supported by IE
        for (let j = 0, cssLen = sheet.cssRules.length; j < cssLen; ++j) {
          if (typeof sheet.cssRules[j].cssText === "string") {
            styleCSS += `${sheet.cssRules[j].cssText}\r\n`;
          }
        }
        newHeadEl.setAttribute("id", `react-to-print-${i}`);
        if (nonce) {
          newHeadEl.setAttribute("nonce", nonce);
        }
        newHeadEl.appendChild(domDoc.createTextNode(styleCSS));
        domDoc.head.appendChild(newHeadEl);
      }
    } else {
      // Many browsers will do all sorts of weird things if they encounter an
      // empty `href` tag (which is invalid HTML). Some will attempt to load
      // the current page. Some will attempt to load the page"s parent
      // directory. These problems can cause `react-to-print` to stop without
      // any error being thrown. To avoid such problems we simply do not
      // attempt to load these links.
      if (node.getAttribute("href")) {
        const newHeadEl = domDoc.createElement(node.tagName);

        // node.attributes has NamedNodeMap type that is not an Array and
        // can be iterated only via direct [i] access
        for (let j = 0, attrLen = node.attributes.length; j < attrLen; ++j) {
          // eslint-disable-line max-len
          const attr = node.attributes[j];
          if (attr) {
            newHeadEl.setAttribute(attr.nodeName, attr.nodeValue || "");
          }
        }

        if (nonce) {
          newHeadEl.setAttribute("nonce", nonce);
        }
        domDoc.head.appendChild(newHeadEl);
      } else {
        // this.logMessages(['"react-to-print" encountered a <link> tag with an empty "href" attribute. In addition to being invalid HTML, this can cause problems in many browsers, and so the <link> was not loaded. The <link> is:', node], 'warning')
        // `true` because we"ve already shown a warning for this
      }
    }
  }
};
// Print handler for Safari
const getSafariPrintHandler = (
  componentRef: any,
  onAfterPrint: () => void,
  documentTitle?: any
) => () => {
  const popupWindow: any = window.open(
    "",
    documentTitle,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=750, height=450`
  );
  const printDoc = popupWindow.document;
  printDoc.write(`
        <!DOCTYPE>
        <html>
        <head>
            <title>${documentTitle}</title>
        </head>
        <body>
        ${componentRef.current.innerHTML}
        </body>
        </html>
    `);
  // // apply the default print styles here: https://github.com/gregnb/react-to-print/blob/master/src/index.tsx#L29
  const styleEl = printDoc.createElement("style");
  const pagePrintStyleDefault =
    "@page { size: auto;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }";
  styleEl.appendChild(printDoc.createTextNode(pagePrintStyleDefault));
  printDoc.head.appendChild(styleEl);

  // copy styles logic from react-to-print
  copyStyles(printDoc);

  popupWindow.onafterprint = () => {
    popupWindow.close();
    onAfterPrint();
  };
  // clean up
  popupWindow.print();
  printDoc.close();
  // p;
};
export const usePrint = ({
  componentRef,
  onAfterPrint,
}: {
  componentRef: any;
  onAfterPrint: () => void;
}) => {
  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    onAfterPrint,
  });
  if (isSafari) {
    return getSafariPrintHandler(componentRef, onAfterPrint);
  }

  return handlePrint;
};
