import React, {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import {
  GetShippingAPIParams2,
  ShippingItemListType,
} from "../../../api/apiTypes";
import { styleShippingCluster } from "../../../fns/commonFns";
import {
  ClusterLayer,
  Controller,
  OpenLayers,
  CustomOverlay,
} from "@delivus/react-open-layers";
import ShippingsTooltip, {
  THoveredShipping,
} from "../shippingMap/ShippingsTooltip";
import Feature from "ol/Feature";
import { useTranslation } from "react-i18next";
import {
  HoveredShippingTooltipType,
  vworldBaseLayer,
} from "../../screens/shippingMap/ShippingMapScreen";
import { Style, Icon } from "ol/style";
import { SelectEvent } from "ol/interaction/Select";
import { MINT, PRIMARY } from "../../../common/consts.common";
import { getMarkerIcon, useShippings } from "../../../api/useShippings";
import { Button } from "antd";
import RefreshOutlined from "../../../assets/svgs/refreshOutlined.svg";

interface Props {
  params: GetShippingAPIParams2;
  onSelectShippings: (shippings: ShippingItemListType[]) => void;
  onRefresh: () => void;
}

const ShippingMap = forwardRef(
  ({ onSelectShippings, onRefresh, params }: Props, ref) => {
    const { t } = useTranslation(["shipping"]);
    const mapRef = useRef<any>();
    const [hoveredShippingTooltip, setHoveredShippingTooltip] = useState<
      HoveredShippingTooltipType
    >();
    const [isVisibleTooltip, setIsVisibleTooltip] = useState(true);
    const { shippingMarkers, mutate } = useShippings(params);
    const selectedFeatureRef = useRef<Feature[]>([]);

    useImperativeHandle(
      ref,
      () => ({
        reset: handleReset,
        refresh: () => {
          console.log("useShippings mutate");
          mutate();
        },
      }),
      []
    );

    const removeFromSelectedShippings = (feature: Feature, properties: any) => {
      unhighlighFeature(feature);
      feature.setProperties({ ...properties, highlighted: false });
      selectedFeatureRef.current = selectedFeatureRef.current.filter(
        (selected) => {
          return selected !== feature;
        }
      );
      console.log("filter feature", selectedFeatureRef.current, feature);
    };

    const handleClickCluster = useCallback((selected: Feature[]) => {
      console.log("handleClickCluster", selected);
      if (selected?.length) {
        let features: Feature[] = selected[0].get("features");
        if (!features?.length) {
          //is not cluster
          features = selected;
        }
        let highlighted = false;
        features &&
          features.forEach((f: Feature) => {
            const properties = f.getProperties();
            if (properties.shipping) {
              if (!highlighted) highlighted = properties.highlighted;
              console.log("handleClick", properties);
              if (!properties.highlighted) {
                addToSelectedShippings(f, properties);
              } else {
                removeFromSelectedShippings(f, properties);
              }
            }
          });
        const shippings = getSelectedShippings();
        onSelectShippings(shippings);
      }
    }, []);

    const handleMouseOverCluster = useCallback(
      (selected: Feature[], deselected: Feature[], event: SelectEvent) => {
        console.log("handleMouseOverCluster1", selected, deselected);
        const shippings: THoveredShipping[] = [];
        let selectedFeatures: Feature[] = [];
        if (selected?.length) {
          selectedFeatures = selected[0].get("features");
          if (!selectedFeatures?.length) {
            //is not cluster
            selectedFeatures = selected;
          }
          console.log("handleMouseOverCluster features", selectedFeatures);
          selectedFeatures &&
            selectedFeatures.forEach((feature) => {
              const properties = feature.getProperties();
              console.log("handleMouseOverCluster properties", properties);
              const shipping: ShippingItemListType = properties.shipping;
              if (shipping) {
                highlightFeature(feature, "#" + PRIMARY);
                shippings.push({
                  tracking_number: shipping.tracking_number,
                  status: shipping.status,
                });
              }
            });
          if (shippings.length > 0)
            setHoveredShippingTooltip({
              shippings,
              position: event.mapBrowserEvent.coordinate,
            });
        }
        if (deselected?.length) {
          let deselectedFeatures: Feature[] = deselected[0].get("features");
          if (!deselectedFeatures?.length) {
            //is not cluster
            deselectedFeatures = deselected;
          }
          if (selectedFeatures[0] === deselectedFeatures[0]) return;
          setHoveredShippingTooltip(undefined);
          console.log(
            "handleMouseOverCluster desect features",
            deselectedFeatures
          );
          deselectedFeatures &&
            deselectedFeatures.forEach((feature) => {
              const properties = feature.getProperties();
              console.log(
                "handleMouseOverCluster  desect properties",
                properties
              );
              if (!properties.highlighted) unhighlighFeature(feature);
              else highlightFeature(feature, "#" + MINT);
            });
        }
      },
      []
    );

    const unhighlighFeature = (feature: Feature) => {
      const properties = feature.getProperties();
      feature.setStyle([
        new Style({
          image: new Icon(getMarkerIcon(properties.color)),
        }),
      ]);
    };

    const highlightFeature = (feature: Feature, color: string) => {
      feature.setStyle([
        new Style({
          image: new Icon(getMarkerIcon(color)),
        }),
      ]);
    };

    const addToSelectedShippings = (feature: Feature, properties: any) => {
      selectedFeatureRef.current.push(feature);
      highlightFeature(feature, "#" + MINT);
      feature.setProperties({ ...properties, highlighted: true });
      console.log(
        "addToSelectedShippings feature",
        selectedFeatureRef.current,
        feature
      );
    };
    const getSelectedShippings = () => {
      const shippings: ShippingItemListType[] = [];
      selectedFeatureRef.current &&
        selectedFeatureRef.current.forEach((f) => {
          const properties = f.getProperties();
          if (properties.shipping) shippings.push(properties.shipping);
        });
      return shippings;
    };

    const handleReset = useCallback(() => {
      selectedFeatureRef.current &&
        selectedFeatureRef.current.forEach((feature) => {
          const properties = feature.getProperties();
          removeFromSelectedShippings(feature, properties);
        });
    }, []);

    const rightControlNode = (
      <>
        <Button
          id={"tooltip-btn"}
          className={
            "floating-btn" + (!isVisibleTooltip ? " primary-bg white" : "")
          }
          onClick={() => setIsVisibleTooltip((prev) => !prev)}
        >
          {t("btn.hide")}
        </Button>
        <Button
          className={"floating-btn"}
          onClick={onRefresh}
          icon={
            <img
              alt={"refresh"}
              className={"refresh-img"}
              src={RefreshOutlined}
            />
          }
        />
      </>
    );

    return (
      <OpenLayers
        ref={mapRef}
        layers={[vworldBaseLayer]}
        className={"shipping-map"}
        interactionOptions={{ pinchZoom: true }}
        showZoom
      >
        <Controller
          id={"right-control"}
          className={"map-floating-cntr map-right-floating shipping-float"}
        >
          {rightControlNode}
        </Controller>
        <CustomOverlay
          id={"shipping-tooltip"}
          className={"tooltip"}
          position={hoveredShippingTooltip?.position}
        >
          <ShippingsTooltip shippings={hoveredShippingTooltip?.shippings} />
        </CustomOverlay>
        <ClusterLayer
          clusterStyle={styleShippingCluster}
          points={shippingMarkers}
          onOver={handleMouseOverCluster}
          onClick={handleClickCluster}
        />
      </OpenLayers>
    );
  }
);

export default memo(ShippingMap);
