import { AxiosResponse } from "axios";
import { refreshToken, verifyToken } from "../api/authApi";
import { RefreshApiResponse } from "../api/apiTypes";
import ExpiryMap from "expiry-map";
import mimicFn from "mimic-fn";

const cache = new ExpiryMap(10000);
export class TokenStorage {
  private static cacheStore = new WeakMap();
  static LOCAL_STORAGE_TOKEN = "access";
  static readonly LOCAL_STORAGE_REFRESH_TOKEN = "refresh_token";
  private static access: string | null = null;

  public static isAuthenticated(): boolean {
    return !!this.getToken();
  }

  public static verifyToken(
    onSuccess: (token: RefreshApiResponse) => void,
    onFail: (e: any) => void
  ) {
    verifyToken(this.getToken())
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((error) => {
        onFail(error);
      });
  }

  private static getNewToken(): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      refreshToken(TokenStorage.getRefreshToken())
        .then((response) => {
          TokenStorage.storeToken(response.data.access);
          resolve(response);
        })
        .catch((error) => {
          TokenStorage.clear();
          reject(error);
        });
    });
  }

  public static storeToken(token: string): void {
    this.access = token;
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token);
  }

  public static storeRefreshToken(refreshToken: string): void {
    localStorage.setItem(
      TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN,
      refreshToken
    );
  }

  public static clear(): void {
    this.access = "";
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  public static getRefreshToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  public static getToken(): string | null {
    if (this.access) return this.access;
    if (localStorage && TokenStorage.LOCAL_STORAGE_TOKEN !== null) {
      return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    }
    return null;
  }

  static pMemoize(
    fn: (arguments_?: any) => Promise<any>,
    {
      cacheKey = ([firstArgument]: any[]) => firstArgument,
      cache = new ExpiryMap(1000),
    } = {}
  ) {
    // Promise objects can't be serialized so we keep track of them internally and only provide their resolved values to `cache`
    // `Promise<AsyncReturnType<FunctionToMemoize>>` is used instead of `ReturnType<FunctionToMemoize>` because promise properties are not kept
    const promiseCache = new Map();
    const memoized = function (...arguments_: any[]) {
      const key = cacheKey(arguments_);
      if (promiseCache.has(key)) {
        return promiseCache.get(key);
      }
      const promise = (async () => {
        try {
          if (cache && (await cache.has(key))) {
            return await cache.get(key);
          }

          // @ts-ignore
          const promise = fn.apply(this, arguments_);
          const result = await promise;
          try {
            return result;
          } finally {
            if (cache) {
              await cache.set(key, result);
            }
          }
        } finally {
          promiseCache.delete(key);
        }
      })();
      promiseCache.set(key, promise);
      return promise;
    };
    mimicFn(memoized, fn);
    this.cacheStore.set(memoized, cache);
    return memoized;
  }

  public static cachedGetNewToken = async () => {
    const cachedFetchTodos = TokenStorage.pMemoize(TokenStorage.getNewToken, {
      cache,
    });
    return await cachedFetchTodos();
  };
}
