import {fetcher} from "../services/http.service";
import {GetListResponse, SectorType, UnitItemType} from "./apiTypes";
import {useMemo} from "react";
import useSWRInfinite from "swr/infinite";
import {queries} from "./queries";
import {CheckType} from "../components/organisms/searchView/SearchView";

export function useSectorOptions(t: (text: string) => string) {
    const getKey = (
        pageIndex: number,
        previousPageData: GetListResponse<UnitItemType>
    ) => {
        if (pageIndex === 0) {
            return queries.location.sectors;
        }
        if (previousPageData && previousPageData.next) return previousPageData.next; // reached the end
        return null;
    };

    const {data, error, isValidating, size, setSize} = useSWRInfinite<
        GetListResponse<SectorType>
    >(getKey, fetcher, {initialSize: 1});

    const sectorOptions = useMemo(() => {
        console.log("units", data);
        const options: CheckType[] = [{title: t("all"), value: "all"}];
        if (data && data?.length > 0) {
            ;
            const lastRes = data[data?.length - 1];
            if (
                lastRes &&
                lastRes.current_page * lastRes.items_per_page < lastRes.count
            )
                setSize(size + 1);
            else {
                data.forEach(({results}) => {
                    if (results) {
                        results.forEach((r) => {
                            const opt: CheckType = {title: r.code, value: r.code};
                            options.push(opt);
                        });
                    }
                });
            }
        }
        return options;
    }, [data, setSize, size]);

    return {
        sectorOptions,
        isLoading: isValidating,
        isError: error,
    };
}
