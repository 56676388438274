import React, { useEffect, useState } from "react";
import styles from "./plan.module.css";

import { Button, Modal, Radio } from "antd";
import { useTranslation } from "react-i18next";
import { ContainerType } from "../../../api/apiTypes";
import {
  showApiError,
  showMessage,
  showMessageWithTitle,
} from "../../../fns/message";
import Lottie from "lottie-react";
import spinner from "../../../assets/lotties/spinner.json";
import http from "../../../services/http.service";
import { queries } from "../../../api/queries";

type TCreatePlanAPIData = {
  tracking_numbers: string[];
  plan_no: string | number;
  bunny_color: ContainerType;
  plan_date: string;
};

interface Props {
  planNo: string | number;
  planDate: string;
  trackingNumbers: string[];
  onCreated: () => void;
  onHide: () => void;
}

export const CreatetPlanPopup = ({
  onCreated,
  onHide,
  trackingNumbers,
  planNo,
  planDate,
}: Props) => {
  const { t } = useTranslation("shipping");
  const [selectedType, setSelectType] = useState<ContainerType>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("shiftplan useEffect");
  }, []);

  const handleCreate = (e: any) => {
    if (!!selectedType) {
      setIsLoading(true);
      const data: TCreatePlanAPIData = {
        tracking_numbers: trackingNumbers,
        plan_no: planNo,
        bunny_color: selectedType,
        plan_date: planDate,
      };
      http
        .post(queries.plan.create, data)
        .then(() => {
          showMessageWithTitle(
            t("popup.title"),
            t("Plan was created successfully"),
            "info",
            t("popup.btn.ok")
          );
          onHide();
          onCreated();
        })
        .catch(showApiError)
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      showMessage("Please, select bunny type");
    }
  };

  const buttonNode = (
    <div className={"row "}>
      <Button
        type={"text"}
        className={"white bold body popup-cancel"}
        onClick={onHide}
      >
        {t("popup.btn.back")}
      </Button>
      <div className={"modal-divider"} />
      <Button
        type={"text"}
        className={"bold body popup-ok"}
        onClick={handleCreate}
      >
        {t("shipping.status.btn.create.plan")}
      </Button>
    </div>
  );

  return (
    <Modal
      closable={false}
      cancelText={""}
      open={true}
      maskClosable
      centered
      className={"sm-modal"}
      footer={buttonNode}
    >
      <div className={"white body bold " + styles.mergeTitle}>
        {t("Plan bunny type")}
      </div>
      <Radio.Group
        onChange={(e: any) => setSelectType(e.target.value)}
        className={styles.radioGrp}
        name="radiogroup"
      >
        <Radio
          style={{ marginTop: "6px" }}
          className={"white bold small"}
          value={ContainerType.BLACK}
        >
          {ContainerType.BLACK}
        </Radio>

        <Radio
          style={{ marginTop: "6px" }}
          className={"white bold small"}
          value={ContainerType.BLUE}
        >
          {ContainerType.BLUE}
        </Radio>

        <Radio
          style={{ marginTop: "6px" }}
          className={"white bold small"}
          value={ContainerType.WHITE}
        >
          {ContainerType.WHITE}
        </Radio>

        <Radio
          style={{ marginTop: "6px" }}
          className={"white bold small"}
          value={ContainerType.OFFROAD}
        >
          {ContainerType.OFFROAD}
        </Radio>

        <Radio
          style={{ marginTop: "6px" }}
          className={"white bold small"}
          value={ContainerType.RAINBOW}
        >
          {ContainerType.RAINBOW}
        </Radio>

        <Radio
          style={{ marginTop: "6px" }}
          className={"white bold small"}
          value={ContainerType.YELLOW}
        >
          {ContainerType.YELLOW}
        </Radio>

        <Radio
          style={{ marginTop: "6px" }}
          className={"white bold small"}
          value={ContainerType.ORANGE}
        >
          {ContainerType.ORANGE}
        </Radio>
      </Radio.Group>
      {isLoading && (
        <div className={styles.lottieVisible}>
          <Lottie animationData={spinner} loop autoplay style={{height: 25}} />
        </div>
      )}
    </Modal>
  );
};
