import React, {useMemo} from "react";
import styles from "./filter.module.css";
import "../unitMap/tooltip.css";
import {useTranslation} from "react-i18next";

export type THoveredShipping = {
    tracking_number: string;
    status: string;
    complete?: boolean;
};
export const PlansTooltip = ({
                                 shippings,
                             }: {
    shippings?: THoveredShipping[];
}) => {
    const {t} = useTranslation("shipping");
    const filtered = useMemo(() => {
        if (shippings && shippings.length > 4) {
            const tmp: { [key: string]: { [key: string]: { count: number, complete?: boolean } } } = {}
            shippings.forEach((s) => {
                if (tmp[s.tracking_number]) {
                    if (!!tmp[s.tracking_number][s.status])
                        ++tmp[s.tracking_number][s.status].count;
                    else
                        tmp[s.tracking_number][s.status] = {count: 1, complete: s.complete};
                } else {
                    tmp[s.tracking_number] = {[s.status]: {count: 1, complete: s.complete}}
                }
            });
            console.log("filtered tmp", tmp, Object.keys(tmp).map((key) => [key, tmp[key]]))
            return tmp;
        }
        return {};
    }, [shippings]);

    if (shippings && shippings.length > 4) {
        return (
            <div className={styles.tooltipCntr}>
                {filtered && Object.keys(filtered).map((sector, i) => {
                    const statusObj = filtered[sector];
                    const statusList = Object.keys(statusObj);
                    return (
                        <div key={"shippinginfo" + i} className={"row small space-between"}>
                            <span className={"bold"}>{sector}</span>
                            <div className={"column"}>
                            {statusList.map((status) => (
                                <div key={"shippinginfo" + i} className={"row small space-between"}>
                                    <span className={"bold align-center"}>{t(status)}</span>
                                    <div className={"row align-center dot-container"}>
                                        <div
                                            className={
                                                styles.dot + " " + (statusObj[status].complete ? styles.grey : styles.green)
                                            }
                                        />
                                        {t(statusObj[status].count+"")}
                                    </div>
                                </div>
                            ))}
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    }
    if (shippings && shippings.length)
        return (
            <div className={styles.tooltipCntr}>
                {shippings &&
                    shippings.map((s, i) => (
                        <div key={"shippinginfo" + i} className={"row small info-row"}>
                            <span className={"bold"}>{s.tracking_number}</span>
                            <div className={"row align-center info-label-shipping"}>
                                <div
                                    className={
                                        styles.dot + " " + (s.complete ? styles.grey : styles.green)
                                    }
                                />
                                {t(s.status)}
                            </div>
                        </div>
                    ))}
            </div>
        );

    return null;
};
