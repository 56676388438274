import React, {RefObject, useCallback, useRef, useState} from "react";
import "./box.style.css";
import {useTranslation} from "react-i18next";
import InfiniteList, {InfiniteListRef} from "../../molecules/list/InfiniteList";
import {CheckinBoxApiParams, CheckoutBoxApiParams, LogicalBoxType,} from "../../../api/apiTypes";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import {MemoizedBoxItem} from "../../organisms/BoxRegisterItem";
import {checkinBatchBox, checkinPlanAll, checkoutBatchBox, getContainerSts, updateContainer,} from "../../../api/shippingApi";
import {updateBoxSts} from "../../../reducers/profileReducer";
import {useDispatch} from "react-redux";
import {isDesktopByWidth} from "../../../fns/commonFns";
import EmptyContent from "../../molecules/emptyContent/EmptyContent";
import SearchView, {SearchResultType,} from "../../organisms/searchView/SearchView";
import {showApiError, showMessage, showMessageWithTitle,} from "../../../fns/message";
import {BoxLabelFormat} from "@delivus/daas-print-lib";
import PrintView from "../../molecules/print/PrintView";
import TabbarView from "../../molecules/tabbar/TabbarView";
import {MenuProps} from "antd";
import PopupSpinner from "../../molecules/spinner/PopupSpinnerView";
import {useSectorOptions} from "../../../api/useSectorOptions";

const BoxRegister = () => {
  const {t} = useTranslation(["box"]);
  const dispatch = useDispatch();
  const printRef = useRef<any>();
  const listRef = useRef() as RefObject<InfiniteListRef>;
  const dataRef = useRef<LogicalBoxType[]>();
  const [selectedItems, setSelectedItems] = useState<LogicalBoxType[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [params, setParams] = useState<any>();
  const [location, setLocation] = useState("all");
  const {sectorOptions} = useSectorOptions(t);
  
  const handleGetCount = useCallback((count: number, data: any[]) => {
    dataRef.current = data;
  }, []);
  
  const handleRegisterDone = useCallback(() => {
    if (listRef.current) listRef.current.requestRefresh();
    setSelectedItems([]);
    getContainerSts((cnt) => {
      dispatch(updateBoxSts(cnt));
    });
  }, []);
  
  const handleSearch = ({inputValue, selectedOption}: SearchResultType) => {
    setParams({search: inputValue});
    setLocation(selectedOption || "");
  };
  
  const handlePrint = useCallback((boxes?: LogicalBoxType[], damaged?: boolean) => {
    const array = !!boxes && boxes.length > 0 ? boxes : selectedItems;
    const invoivesNode = array && array.map(({box, container}, index: number) => box?.alias && (<BoxLabelFormat
      key={"print" + index}
      alias={box?.alias}
      unit_storage_name={container?.unit_storage || ""}
    />));
    if (!!printRef.current && invoivesNode) {
      printRef.current.setContent(invoivesNode, damaged);
    }
    else {
      showMessageWithTitle(t("box.item.popup.print.success.title"), t("error.popup.box.print.notselected"), "warning");
    }
  }, [selectedItems]);
  
  const onPrinted = (damaged?: boolean) => {
    window.onfocus = null;
    if (damaged) {
      showMessageWithTitle(t("box.item.popup.print.success.title"), t("box.item.popup.print.success.content"));
    }
    else {
      const array = selectedItems.length > 0 ? selectedItems : dataRef.current;
      if (array) {
        const promises = array.map((box) => {
          if (box.container) return updateContainer(box.container.uuid, {count_box: (box.container.count_box || 0) + 1})
          return null;
        });
        Promise.allSettled(promises)
               .then(() => {
                 handleRegisterDone();
                 showMessageWithTitle(t("box.item.popup.print.success.title"), t("box.item.popup.print.success.content"));
               });
      }
    }
  };
  
  const handleCheckin = (key: string, data?: LogicalBoxType[]) => {
    const array = !!data && data.length > 0 ? data : selectedItems;
    if (!!array && array.length > 0) {
      const uuidList: CheckinBoxApiParams = [];
      array.forEach((s: LogicalBoxType) => {
        if (!s.box && s.sector?.id) {
          uuidList.push({
            sector_id: s.sector?.id, is_plan: key === t("box.item.btn.checkin.plan")
          });
        }
      });
      if (uuidList.length > 0) {
        setLoading(true)
        if (key === t("box.item.btn.checkin.gen")) {
          checkinBatchBox(uuidList)
          .then(() => {
            handleRegisterDone();
            showMessage(t("popup.checkin.done"));
          })
          .catch((err) => {
            showApiError(err);
          })
          .finally(() => {
            setLoading(false)
          })
        }
        else {
          checkinPlanAll(uuidList)
          .then(() => {
            handleRegisterDone();
            showMessage(t("popup.checkin.done"));
          })
          .catch(showApiError)
          .finally(() => {
            setLoading(false)
          })
        }
      }
      else {
        showMessage(t("error.popup.checkin.empty.available"));
      }
    }
    else {
      showMessageWithTitle(t("box.item.popup.print.success.title"), t("error.popup.sector.empty"), "warning");
    }
  };
  
  const handleClose = (data?: LogicalBoxType[]) => {
    const array = !!data && data.length > 0 ? data : selectedItems;
    if (!!array && array.length > 0) {
      const uuidList: CheckoutBoxApiParams = [];
      array.map((s: LogicalBoxType) => {
        if (s.box?.uuid) uuidList.push({box_uuid: s.box?.uuid});
      });
      checkoutBatchBox(uuidList)
      .then(() => {
        handleRegisterDone();
        if (data && data.length > 0) showMessage(t("popup.checkout.all.done"));
        else showMessage(t("popup.checkout.done"));
      })
      .catch(showApiError);
    }
    else {
      showMessageWithTitle(t("box.item.popup.print.success.title"), t("error.popup.box.print.notselected"), "warning");
    }
  };
  
  const handleSelect = useCallback((box: LogicalBoxType) => {
    setSelectedItems(prevSelected => {
      if (prevSelected.includes(box)) return prevSelected.filter((prevBox) => prevBox !== box);
      return [...prevSelected, box];
    })
  }, []);
  
  const items: MenuProps["items"] = [
    {
      label: t("box.item.btn.checkin.gen"), key: t("box.item.btn.checkin.gen"),
    }, {
      label: t("box.item.btn.checkin.plan"), key: t("box.item.btn.checkin.plan"),
    },
  ];
  
  const buttons = [
    {
      title: t("box.reg.checkin.all"),
      onClick: () => console.log("open dropdown"),
      objectType: "dropdown",
      menu: {items, onClick: (key) => handleCheckin(key, dataRef.current), selectable: true},
    },
    {title: t("box.reg.checkin"), onClick: handleCheckin},
    {title: t("box.reg.close.all"), onClick: () => handleClose(dataRef.current)},
    {title: t("box.reg.close"), onClick: () => handleClose()},
    {title: t("box.reg.print.all"), onClick: () => handlePrint(dataRef.current)},
    {title: t("box.reg.print"), onClick: () => handlePrint()},
  ];
  
  return (<>
    <LeftTitledTemplate classname={" box-register-container "} showBreadTitle>
      <SearchView
        searchLabel={t("box.search.label")}
        options={sectorOptions}
        buttons={buttons}
        onSearch={handleSearch}
      />
      <InfiniteList
        ref={listRef}
        url={"/v1/hub/containers/sectors/"}
        loadingClass={"box-register-loading"}
        params={params}
        onGetCount={handleGetCount}
        height={isDesktopByWidth() ? window.innerHeight - 260 : window.innerHeight - 150}
      >
        {(count?: number, results?: LogicalBoxType[], isLoading?: boolean) => (<div>
          {results && results.length > 0
            ? results.map((box, index) => (location === 'all' || location === box.sector?.code) && (<MemoizedBoxItem
              key={"order" + index}
              data={box}
              data-cy="boxDetailRemove"
              selected={selectedItems.includes(box)}
              onClick={handleSelect}
              onPrint={handlePrint}
              onCloseDone={handleRegisterDone}
            />))
            : isLoading || (<EmptyContent
            title={t("empty.title")}
            label={t("empty.content")}
          />)}
        </div>)}
      </InfiniteList>
      {isLoading && <PopupSpinner />}
      <PrintView
        ref={printRef}
        onPrinted={onPrinted}
        btnOk={t("box.item.btn.label")}
      />
    </LeftTitledTemplate>
    <TabbarView />
  </>);
};

export default BoxRegister;
