import {configureStore, Tuple} from "@reduxjs/toolkit";
import logger from "redux-logger";
import {thunk} from "redux-thunk";
import rootReducer, { RootState } from "./reducer";

function saveToLocalStorage(state: RootState) {
  try {
    const serializedState = JSON.stringify({
      auth: state.auth,
      profile: state.profile,
    });
    localStorage.setItem("state", serializedState);
  } catch (e) {}
}

function loadFromLocalStorage(): RootState | undefined {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}
const preloadedState = loadFromLocalStorage();

const store = configureStore({
  reducer: rootReducer,
  preloadedState,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(thunk).concat(logger)
});

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./reducer", () => {
    const newRootReducer = require("./reducer").default;
    store.replaceReducer(newRootReducer);
  });
}

store.subscribe(() => saveToLocalStorage(store.getState()));
export type AppDispatch = typeof store.dispatch;
export type AppThunk = any;

export default store;
