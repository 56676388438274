import React, {ReactNode} from "react";
import {Navigate} from "react-router-dom";
import {MenuTemplate} from "@delivus/daas-leftmenu-template";
import menuData from "../datas/menu.data.json";
import menuhubData from "../datas/menuhub.data.json";
import menuUnitData from "../datas/menuUnit.data.json";
import {useSelector} from "react-redux";
import {RootState} from "../reducers/reducer";
import {PERM_MASTER, PERM_SORTING, PERM_UNIT} from "../common/consts.common";
import {useTranslation} from "react-i18next";

type Props = {
    isLoggedIn: boolean;
    showBreadTitle?: boolean;
    children: ReactNode;
};

function ProtectedRouteComponent({
                                     children,
                                     isLoggedIn,
                                     showBreadTitle,
                                 }: Props) {
    const {t} = useTranslation("common");
    const {permission_group} = useSelector((state: RootState) => state.profile);

    const menus =
        permission_group &&
        (permission_group.includes(PERM_MASTER) ||
            permission_group.includes(PERM_SORTING))
            ? menuData
            : permission_group && permission_group.includes(PERM_UNIT)
                ? menuUnitData
                : menuhubData;

    if (!isLoggedIn) {
        return (
            <Navigate to="/auth/login" replace/>
        );
    }

    return (<MenuTemplate
        header={t("header")}
        t={t}
        showBreadTitle={showBreadTitle}
        menuData={menus}
        defaultOpenKeys={["main", "dispatch", "home", "status", "route"]}
    >{children}</MenuTemplate>)
}

export default ProtectedRouteComponent;
