import React, {forwardRef, useCallback, useImperativeHandle, useMemo, useRef} from "react";
import PullToRefresh from "../pullRefresh/PullToRefresh";
import useSWRInfinite from "swr/infinite";
import "./list.style.css";
import { objToQueryString } from "../../../fns/commonFns";
import { GetListResponse } from "../../../api/apiTypes";
import { fetcher } from "../../../services/http.service";
import spinner from "../../../assets/lotties/spinner.json";
import Lottie from "lottie-react";

export interface InfiniteListRef {
  requestRefresh: () => void;
}

export interface InfiniteListProps {
  params?: any;
  url: string | null;
  height?: any;
  refresh?: boolean;
  children: (count?: number, data?: any[], loading?: boolean) => any;
  onGetCount?: (count: number, data: any[]) => void;
  loadingClass?: string;
  className?: string;
  style?: any;
  onRefresh?: () => void;
}

const InfiniteList = forwardRef<InfiniteListRef, InfiniteListProps>(({
  children,
  url,
  params,
  height,
  style = {},
  onRefresh,
  onGetCount,
  ...rest
}: InfiniteListProps, ref) => {
  let previousPageIdx = useRef(0);
  const paramString = useMemo(() => objToQueryString(params), [params]);

  const getKey = (pageIndex: number, previousPageData?: GetListResponse) => {
    console.log("getKey ", pageIndex, url, paramString);
    if (pageIndex === 0 && url) {
      if (paramString) {
        if (url.includes("?")) return `${url}&${paramString}`;
        return `${url}?${paramString}`;
      }
      return url;
    }
    if (previousPageData && previousPageData.next) {
      return previousPageData.next;
    }
    return null; // reached the end
  };

  const { data, setSize, mutate, isValidating } = useSWRInfinite<
    GetListResponse
  >(getKey, fetcher, { revalidateFirstPage: true });

  const requestRefresh = useCallback(() => {
    mutate();
    if (onRefresh) {
      onRefresh();
    }
  }, [mutate]);

  useImperativeHandle(
      ref,
      () => ({
        requestRefresh,
      }),
      [requestRefresh],
  );

  const listData = useMemo(() => {
    let listData: any[] = [];
    if (data) {
      data.forEach((item) => {
        if (item?.results) {
          listData = listData.concat(item.results);
        }
      });
    }
    if (onGetCount) {
      onGetCount(data && data?.length > 0 ? data[0]?.count : 0, listData);
    }
    console.log("infinite list ", listData, url);
    return listData;
  }, [data]);

  const handleEndReach = useCallback(() => {
    if (data && data?.length > 0) {
      const last = data[data?.length - 1];
      if (previousPageIdx.current < last.current_page && last.next) {
        previousPageIdx.current = last.current_page;
        setSize((prev) => prev + 1);
      }
    }
  }, [data, setSize]);

  return (
    <PullToRefresh
      loading={true}
      resistance={1}
      style={{ height, ...style }}
      onRefresh={requestRefresh}
      onScrollEnd={handleEndReach}
      {...rest}
    >
      <div>
        {children(
          data && data?.length > 0 ? data[0]?.count : 0,
          listData,
          isValidating
        )}
        {isValidating && (
          <Lottie animationData={spinner} loop autoplay style={{height: 25}}/>
        )}
      </div>
    </PullToRefresh>
  );
});

export default InfiniteList;
