import copy from "copy-to-clipboard";
import {showMessage} from "./message";
import CircleStyle from "ol/style/Circle";
import {Fill, Stroke, Style, Text} from "ol/style";
import {MINT, NEUTRAL, PRIMARY} from "../common/consts.common";
import Feature from "ol/Feature";
import {TShippingGeo} from "../api/apiTypes";
import {TPlanMarkerProperty} from "../components/organisms/plan/PlanMap";

export const isBrowser = () => typeof window !== "undefined";
export const isDesktopByWidth = () => window?.innerWidth > 768; //모바일 전환 768px 미만
export const handleCopy = (uuid: string) => {
    document.execCommand("copy", true, uuid);
    copy(uuid);
    showMessage("복사했습니다");
};

export function objectToStringArray(
    o: any,
    arraySupported?: boolean
): string[] {
    const keyValuePairs: string[] = [];
    Object.keys(o).forEach((key) => {
        if (Array.isArray(o[key])) {
            if (arraySupported) {
                keyValuePairs.push(
                    `${encodeURIComponent(key)}=${encodeURIComponent(o[key].join(", "))}`
                );
            } else {
                o[key].forEach((str: any) => {
                    keyValuePairs.push(
                        `${encodeURIComponent(key)}=${encodeURIComponent(str)}`
                    );
                });
            }
        } else {
            keyValuePairs.push(
                `${encodeURIComponent(key)}=${encodeURIComponent(o[key])}`
            );
        }
    });
    return keyValuePairs;
}

export function objToQueryString(obj?: any, arraySupported?: boolean) {
    if (!obj) {
        return "";
    }
    let keyValuePairs: string[] = [];
    if (Array.isArray(obj)) {
        obj.forEach((o) => {
            keyValuePairs = [
                ...keyValuePairs,
                ...objectToStringArray(o, arraySupported),
            ];
        });
    } else {
        keyValuePairs = objectToStringArray(obj, arraySupported);
    }
    return keyValuePairs.join("&");
}

export function hexColorDelta(hex1: string, hex2: string) {
    // get red/green/blue int values of hex1
    var r1 = parseInt(hex1.substring(0, 2), 16);
    var g1 = parseInt(hex1.substring(2, 4), 16);
    var b1 = parseInt(hex1.substring(4, 6), 16);
    // get red/green/blue int values of hex2
    var r2 = parseInt(hex2.substring(0, 2), 16);
    var g2 = parseInt(hex2.substring(2, 4), 16);
    var b2 = parseInt(hex2.substring(4, 6), 16);
    // calculate differences between reds, greens and blues
    var r = 255 - Math.abs(r1 - r2);
    var g = 255 - Math.abs(g1 - g2);
    var b = 255 - Math.abs(b1 - b2);
    // limit differences between 0 and 1
    r /= 255;
    g /= 255;
    b /= 255;
    // 0 means opposite colors, 1 means same colors
    return (r + g + b) / 3;
}

const styleCache: any = {};

export function styleShippingCluster(
    resolution: number,
    size: number,
    features: Feature[]
) {
    let radius = 16,
        alpha = 0.9;
    if (resolution > 130) {
        radius = 37;
        alpha = 0.3;
    } else if (resolution > 90) {
        radius = 26;
        alpha = 0.5;
    } else if (resolution > 45) {
        radius = 20;
        alpha = 0.8;
    }
    let color = "";
    for (let i = 0; features && i < features.length; i++) {
        const feature = features[i];
        let style: any = feature.getStyle();
        if (Array.isArray(style)) style = style[0];
        console.log("styleShippingCluster style", style);

        const properties = feature.getProperties() as TPlanMarkerProperty;
        const shipping = properties.shipping as TShippingGeo;
        console.log("styleShippingCluster properties", properties);
        if(properties.highlighted) {
            color = `#${MINT}`;
            break;
        }
        if (style?.image_?.color_) {
            const fill = style?.image_?.color_;
            if (Array.isArray(fill) && fill?.length > 2) {
                color = `rgba(${fill[0]}, ${fill[1]}, ${fill[2]}, ${alpha})`;
            }
            break;
        }
    }
    console.log("styleShippingCluster color", color);
    const key = resolution + color + size;
    console.log("styleShippingCluster key", key, styleCache[key]);
    if (styleCache[key]) return styleCache[key];
    const style = [
        new Style({
            image: new CircleStyle({
                radius: radius,
                stroke: new Stroke({
                    width: 2,
                    color: "#fff",
                }),
                fill: new Fill({
                    color: color ? color : "#3399CC",
                }),
            }),
            text: new Text({
                font: "bold 13px Spoqa Han Sans Neo",
                text: size.toString(),
                fill: new Fill({
                    color: "#fff",
                }),
            }),
        }),
    ];
    styleCache[key] = style;
    return styleCache[key];
}

const GAB = 0.6;  // 0 means opposite colors, 1 means same colors
export const getRandomNumber = (shouldFilter?: boolean) => {
    let random = Math.floor(Math.random() * 16777215);

    let randomHex = random.toString(16).padEnd(6, "0");
    if (
        shouldFilter &&
        (hexColorDelta(randomHex, MINT) > GAB ||
            hexColorDelta(randomHex, PRIMARY) > GAB)
    ) {
        randomHex = getRandomNumber();
    }
    return randomHex;
};
export const isKorean = (str) => {
    const regExp = /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣].*$/;
    if (regExp.test(str)) {
        return true;
    }
    return false;
};

export const isEnglish = (str) => {
    const regExp = /[a-zA-Z].+/;
    if (regExp.test(str)) {
        return true;
    }
    return false;
};

export const isCityOrDistrict = (address: any) => {
    const regExp = /.*[시|구]{1}$/;
    console.log("isCityOrDistrict regex", address, regExp.test(address))
    if (regExp.test(address))
        return true;
    return false;
};
