import React from "react";
import { Button, Progress } from "antd";
import "./launch.style.css";
import { useTranslation } from "react-i18next";
import Timer from "../../molecules/timer/TimerView";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearAuthState } from "../../../reducers/authReducer";
import { clearProfile } from "../../../reducers/profileReducer";

const ProfileExpired = () => {
  const { t } = useTranslation(["login"]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigate2Login = () => {
    dispatch(clearAuthState());
    dispatch(clearProfile());
    navigate("/auth/login", {replace: true});
  };

  return (
    <div className={"container profile-container .inner-container"}>
      <div className={"expired-container"}>
        <Timer hoursMinSecs={{ seconds: 3 }} onEnd={navigate2Login}>
          {(cnt: number) => (
            <>
              <Progress
                strokeColor={"black"}
                className={"expired-progress"}
                percent={Math.round((cnt * 100) / 3)}
                showInfo={false}
              />{" "}
              <span className={"body expired-info-dark"}>
                {t("expired.info.dark", { cnt })}
                <span className={"body1 expired-info"}>
                  {t("expired.info")}
                </span>
              </span>
            </>
          )}
        </Timer>

        <Button
          type={"primary"}
          onClick={navigate2Login}
          className={"expired-btn"}
        >
          {t("expired.btn")}
        </Button>
      </div>
    </div>
  );
};

export default ProfileExpired;
