export const PERM_MASTER = "Staff manager";
export const PERM_SORTING = "Staff sorting";
export const PERM_UNIT = "Unit location";
export const GUIDE_LINK =
  "https://drabbit.notion.site/SELLER-X-19121f87b3474d1c9896ca8742cd2f23";
export const SELLER_GUIDE_LINK =
  "https://drabbit.notion.site/SELLER-X-19121f87b3474d1c9896ca8742cd2f23";
export const SELLER_LINK = "https://seller.drabbit.co.kr";
export const BUNNY_LINK = "https://bunny.drabbit.co.kr";

export const MINT = "00D4A1";
export const PRIMARY = "6e6eff";
export const NEUTRAL = "8c8c8c";
