import React, {
    forwardRef,
    useCallback,
    useImperativeHandle, useMemo, useRef,
} from "react";
import {useTranslation} from "react-i18next";
import {GetListResponse, TSectorItem} from "../../../api/apiTypes";
import TableCell from "../../molecules/table/TableCell";
import {queries} from "../../../api/queries";
import {InfiniteTable} from "../../molecules/table/InfiniteTable";
import {fetcher} from "../../../services/http.service";
import useSWR from "swr";
import {isEnglish, isKorean} from "../../../fns/commonFns";
import {RightOutlined, LeftOutlined} from "@ant-design/icons";

type Props = {
    onSearch: (keys: string[]) => void;
};
export const SectorTable = forwardRef(({onSearch}: Props, ref) => {
    const {t} = useTranslation(["shipping"]);
    const selectedKeys = useRef<string[]>([]);
    const {data, isValidating: isLoading, mutate} = useSWR<GetListResponse<TSectorItem>>(
        queries.location.sectors,
        fetcher
    );

    useImperativeHandle(
        ref,
        () => ({
            requestRefresh: mutate,
        }),
        [mutate]
    );

    const sorted = useMemo(() => {
        if (!!data?.results?.sort) {
            let korean: any = {}, english: any = {}, other: any = {};
            data.results.sort().forEach((d) => {
                const code = d.code.replace(/[0-9]/g, '');
                if (isKorean(d.code)) {
                    korean[code] = {code}
                } else if (isEnglish(d.code)) {
                    english[code] = {code}
                } else if (!!d.code) {
                    other[code] = {code}
                }
            })
            const koreanList = Object.keys(korean).map((key) => ({code: key}))
            const englishList = Object.keys(english).map((key) => ({code: key}));
            const otherList = Object.keys(english).map((key) => ({code: key}));
            const sorted = [...koreanList, ...englishList, ...otherList]
            return sorted;
        }
        return [];
    }, [data]);

    const columns = [
        {
            title: <TableCell className={"bold small"} value={t("sector")}/>,
            render: (text: string, p: TSectorItem, s: any) => {
                const selected = selectedKeys.current.includes(p.code);
                return (
                    <TableCell refreshing={isLoading}
                               className={"small black-cl space-between " + (selected ? " bold" : "")}>
                        {p.code}
                        {selected ? <RightOutlined/> : <LeftOutlined/>}
                    </TableCell>
                )
            },
        },
    ];

    const handleChangeRow = useCallback((data: any[], keys: any[]) => {
        onSearch(keys);
        selectedKeys.current = keys;
    }, []);

    return (
        <InfiniteTable
            columns={columns}
            data={sorted}
            onChange={handleChangeRow}
            t={t}
            isLoading={isLoading}
            defaultPageSize={10}
            scrollX={80}
            rowKey={"code"}
            scrollY={"calc(var(--app-height) - var(--navbar-height) - 302px - 96px)"}
        />
    );
});
