import { Button } from "antd";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./navbar.module.css";
import { isDesktopByWidth } from "../../../fns/commonFns";

export type NavbarProps = {
  classname?: string;
  titleClassname?: string;
  title?: string;
  right?: ReactNode;
  left?: string;
  onLeft?: () => void;
  onRight?: () => void;
};

const Navbar = ({
  classname,
  titleClassname,
  left = isDesktopByWidth() ? "" : "뒤로",
  title,
  right,
  onRight,
  onLeft,
}: NavbarProps) => {
  const navigate = useNavigate();

  const onBack = () => {
    if (onLeft) {
      onLeft();
    } else {
      navigate(-1);
    }
  };

  const rightNode = (
    <Button
      className={styles.rightNode + " subtitle2"}
      type="text"
      data-cy="navBarRight"
      onClick={onRight}
    >
      {right}
    </Button>
  );

  const leftNode = (
    <Button
      className={styles.leftNode + " subtitle2"}
      type="text"
      data-cy="navBarBack"
      onClick={onBack}
    >
      {left}
    </Button>
  );

  const titleNode = (
    <span className={styles.titleNode + " subtitle2 bold " + titleClassname}>
      {title}
    </span>
  );

  return (
    <nav className={styles.headerContainer + " " + classname}>
      {leftNode}
      {titleNode}
      {rightNode}
    </nav>
  );
};

export default Navbar;
