import React, { useEffect, useRef, useState } from "react";
import styles from "./plan.module.css";

import { Button, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";
import PlanItem from "./PlanItem";
import { TClusterPlanItem } from "../../../api/apiTypes";
import InfiniteList from "../../molecules/list/InfiniteList";
import {
  showApiError,
  showMessage,
  showMessageWithTitle,
} from "../../../fns/message";
import Lottie from "lottie-react";
import spinner from "../../../assets/lotties/spinner.json";
import http from "../../../services/http.service";
import { queries } from "../../../api/queries";
import { SearchParams } from "./MergePlanPopup";

type TUpdatePlanAPIData = {
  tracking_number_list: string[];
  dest_plan_id: number;
  plan_date: string;
  plan_no: string;
};

interface Props {
  trackingNumbers: string[];
  onShifted: () => void;
  onHide: () => void;
  params: SearchParams;
}

export const ShiftPlanPopup = ({
  onShifted,
  onHide,
  trackingNumbers,
  params: paramsProp,
}: Props) => {
  const { t } = useTranslation("shipping");
  const [selectedPlan, selectPlan] = useState<TClusterPlanItem>();
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState<SearchParams>({
    page_size: 20,
    ...paramsProp,
  });
  const inputValueRef = useRef("");

  useEffect(() => {
    if (paramsProp) setParams((prev) => ({ ...prev, ...paramsProp }));
  }, [paramsProp]);

  const handleShift = (e: any) => {
    if (!!selectedPlan) {
      setIsLoading(true);
      const data: TUpdatePlanAPIData = {
        plan_date: selectedPlan.plan_date,
        plan_no: selectedPlan.plan_no,
        dest_plan_id: selectedPlan.id,
        tracking_number_list: trackingNumbers,
      };
      http
        .post<TUpdatePlanAPIData>(queries.plan.shift, data)
        .then(() => {
          showMessageWithTitle(
            t("popup.title"),
            t("Shippings were shifted successfully"),
            "info",
            t("popup.btn.ok")
          );
          onHide();
          onShifted();
        })
        .catch((e) => {
          showApiError(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      showMessage("Please, select plan to be shifted");
    }
  };

  const handleSearch = (e: any) => {
    setParams((prev) => ({
      ...prev,
      cluster_id__startswith: inputValueRef.current,
    }));
  };

  const buttonNode = (
    <div className={"row "}>
      <Button
        type={"text"}
        className={"white bold body popup-cancel"}
        onClick={onHide}
      >
        {t("popup.btn.back")}
      </Button>
      <div className={"modal-divider"} />
      <Button
        type={"text"}
        loading={isLoading}
        className={"bold body popup-ok"}
        onClick={handleShift}
      >
        {t("shift plan")}
      </Button>
    </div>
  );

  return (
    <Modal
      closable={false}
      cancelText={""}
      open={true}
      maskClosable
      centered
      className={"sm-modal"}
      footer={buttonNode}
    >
      <div className={"white body bold " + styles.mergeTitle}>
        {t("shift plan")}
      </div>
      <Input
        onChange={(e: any) => {
          inputValueRef.current = e.target.value;
          handleSearch(e);
        }}
        className={"popup-input "}
        placeholder={t("search plan")}
        autoComplete={"off"}
        suffix={
          <Button
            type={"text"}
            onClick={handleSearch}
            className={styles.inputBtn + " small bold primary"}
          >
            {t("search")}
          </Button>
        }
      />
      <InfiniteList
        url={queries.plan.short}
        params={params}
        height={300}
        className={styles.list}
      >
        {(count, data, isLoading) =>
          data && data.length > 0
            ? data.map((plan: TClusterPlanItem, id) => (
                <PlanItem
                  key={"rider" + id}
                  selected={plan.id === selectedPlan?.id}
                  plan={plan}
                  onSelect={selectPlan}
                />
              ))
            : isLoading || (
                <div className={"small text-center white"}>
                  {t("rider.empty")}
                </div>
              )
        }
      </InfiniteList>
      {isLoading && (
        <div className={styles.lottieVisible}>
          <Lottie animationData={spinner} autoplay loop style={{height: 25}}/>
        </div>
      )}
    </Modal>
  );
};
