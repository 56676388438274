import React from "react";
import "./spinner.css";
import Lottie from "lottie-react";
import spinner from "../../../assets/lotties/spinner.json";

const PopupSpinner = () => {
    return (
        <div className={"spinner-container"}>
            <Lottie animationData={spinner} loop autoplay  style={{height: 25}}/>
        </div>
    );
};

export default PopupSpinner;
