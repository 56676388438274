import React, { useEffect, useRef, useState } from "react";
import styles from "./plan.module.css";
import spinner from "../../../assets/lotties/spinner.json";

import { Button, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";
import PlanItem from "./PlanItem";
import { GetListParams, TClusterPlanItem } from "../../../api/apiTypes";
import InfiniteList from "../../molecules/list/InfiniteList";
import {
  showApiError,
  showMessage,
  showMessageWithTitle,
} from "../../../fns/message";
import Lottie from "lottie-react";
import http from "../../../services/http.service";
import { queries } from "../../../api/queries";

export interface SearchParams extends GetListParams {
  sector_code?: string;
  sector_code__in?: string[];
  cluster_id__startswith?: string;
}

interface Props {
  onMerged: () => void;
  onHide: () => void;
  plan: TClusterPlanItem;
  params: SearchParams;
}
export const MergePlanPopup = ({
  plan,
  onMerged,
  onHide,
  params: paramsProp,
}: Props) => {
  const { t } = useTranslation("shipping");
  const [selectedId, setSelectedId] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState<SearchParams>({
    page_size: 20,
    ...paramsProp,
  });
  const inputValueRef = useRef("");

  useEffect(() => {
    if (paramsProp) setParams((prev) => ({ ...prev, ...paramsProp }));
  }, [paramsProp]);

  const handleMerge = (e: any) => {
    if (!!selectedId) {
      setIsLoading(true);
      http
        .post(queries.plan.merge(selectedId), { src_plan_id: plan.id })
        .then(() => {
          showMessageWithTitle(
            t("popup.title"),
            t("Plans were merged successfully"),
            "info",
            t("popup.btn.ok")
          );
          onHide();
          onMerged();
        })
        .catch((e) => {
          showApiError(e, t);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      showMessage("Please, select plan to be merged");
    }
  };

  const handleSearch = (e: any) => {
    setParams((prev) => ({
      ...prev,
      cluster_id__startswith: inputValueRef.current,
    }));
  };

  const buttonNode = (
    <div className={"row "}>
      <Button
        type={"text"}
        className={"white bold body popup-cancel"}
        onClick={onHide}
      >
        {t("popup.btn.back")}
      </Button>
      <div className={"modal-divider"} />
      <Button
        type={"text"}
        className={"bold body popup-ok"}
        onClick={handleMerge}
      >
        {t("merge plan")}
      </Button>
    </div>
  );

  return (
    <Modal
      closable={false}
      cancelText={""}
      open={true}
      maskClosable
      centered
      className={"sm-modal"}
      footer={buttonNode}
    >
      <div className={"white body bold " + styles.mergeTitle}>
        {t("merge plan")}
      </div>
      <Input
        onChange={(e: any) => {
          inputValueRef.current = e.target.value;
          handleSearch(e);
        }}
        className={"popup-input"}
        placeholder={t("search plan")}
        autoComplete={"off"}
        suffix={
          <Button
            type={"text"}
            onClick={handleSearch}
            className={styles.inputBtn + " small bold primary"}
          >
            {t("search")}
          </Button>
        }
      />
      <InfiniteList
        url={queries.plan.short}
        params={params}
        height={300}
        className={styles.list}
      >
        {(count, data, isLoading) =>
          data && data.length > 0
            ? data.map(
                (p: TClusterPlanItem, id) =>
                  plan.id !== p.id && (
                    <PlanItem
                      key={"rider" + id}
                      selected={p.id == selectedId}
                      plan={p}
                      onSelect={(selected) => setSelectedId(selected.id)}
                    />
                  )
              )
            : isLoading || (
                <div className={"small text-center white"}>
                  {t("rider.empty")}
                </div>
              )
        }
      </InfiniteList>
      {isLoading && (
        <div className={styles.lottieVisible}>
          <Lottie animationData={spinner} loop autoplay style={{height: 25}}/>
        </div>
      )}
    </Modal>
  );
};
